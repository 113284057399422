.next_case_container {
  background-color: var(--color);
  color: var(--color4);
  /* padding: 120px 70px 40px 70px; */
  font-family: var(--Manrope);

  .next_case_data_container {
    width: 100%;

    .next_case_next_title {
      text-align: center;
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 154.5%;
      /* 30.9px */
    }

    .next_case_case_name {
      margin-top: 40px;
      text-align: center;
      color: #FFF;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 4.16px;
    }

    .next_case_footer_info {
      margin-top: 90px;
      width: 100%;
    }

    .next_case_container {
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .next_case_container1 {
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
    }

    .next_case_container_div_bg {
      display: flex;
      align-items: flex-end;
      height: 180px;
      overflow: hidden;
    }

    .image_height_div {
      height: 80px;
      background: var(--color15);
      padding: 15px;

      img {
        width: 435px;
      }
    }

    .next_case_container_div_border {
      height: 1px;
      background: rgba(255, 255, 255, 0.24);
      width: 1187px;
      top: 100px;
    }

    .next_case_all_work_container {
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-family: var(--Manrope);

      .next_case_all_work_title {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 154.5%;
        /* 24.72px */
      }

      .next_case_work_count {
        color: rgba(255, 255, 255, 0.50);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 154.5%;
      }


    }
  }
}

.circle_position {
  position: relative;
  padding-top: 90px;
  cursor: pointer;
}

.circleButton {
  font-size: var(--Manrope);
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color13);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color4);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.circle_view {
  transform: scale(0.1);
}

@media (min-width: 360px) and (max-width: 480px) {

  .next_case_case_name {
    font-family: var(--Manrope) !important;
    font-size: 36px !important;
    line-height: 50.4px !important;
    letter-spacing: 0.65px !important;
  }

  .next_case_container_div_border {
    width: 100% !important;
  }

  .image_height_div {
    img {
      width: 280px !important;
    }
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .next_case_container_div_border {
    width: 100% !important;
  }

}