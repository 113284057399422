:root {
  --Manrope: 'Manrope';
  --Helvetica_Neue: 'Helvetica Neue';
  --Inter: 'Inter';
  --font-20: 20px;
  --font-weight-400: 400;
  --color: #1E1E1E;
  --color2: #3C3C3C;
  --color3: #54A579;
  --color4: #fff;
  --color5: #8D8E8F;
  --color6: #E2E2E2;
  --color7: rgba(255, 126, 32, 0.31);
  --color8: rgba(230, 237, 251, 1);
  --color9: rgba(251, 245, 241, 1);
  --color10: rgba(254, 190, 0, 0.17);
  --color11: #89898980;
  --color12: #54A579;
  --color13: #3C52E6;
  --color14: #1C1D20;
  --color15: #D9D9D9;
  --color16: #1818184D;
  --color17: #F3F3F3;
  --color18: #1E1E1E80;
  --color19: #3C3C3C99;
  --color20: #F2F2F2;
  --color21: #FF8739;
  --color-blue-dark: #334BD3;
}

.bg_color1 {
  background: var(--color7);
}

.bg_color2 {
  background: var(--color8);
}

.bg_color3 {
  background: var(--color9);
}

.bg_color4 {
  background: var(--color10);
}

body {
  margin: 0;
  font-family: 'Manrope', 'Open Sans';
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.flex {
  display: flex;
}

.jc-space-between {
  justify-content: space-between;
}

button {
  outline: none;
  border: none;
}

.homepage_my_work_container {
  margin-top: 90px;
}

.cursor:hover {
  cursor: pointer;
}

#locomotive-scroll-container {
  position: relative;
}

.sidebar {
  position: fixed;
  z-index: 3;
  top: 0;
  right: -560px;
  /* Start off-screen on the right */
  width: 560px;
  height: 100%;
  color: white;
  transition: transform 0.3s ease;
  /* background: var(--color); */
  background: linear-gradient(to right, hsla(220, 0%, 0%, .3) 0%, hsla(220, 13%, 0%, 1) 0%);
  border-top-left-radius: 50%;
  /* Initial rounded corners */
  border-bottom-left-radius: 50%;
}

.side_menu_option_container {
  height: 100%;
  transition: transform 0.3s ease;
}

/* Opacity */
#create,
#create_home_content {
  display: inline-block;
  transform: translateY(100%);
}

.hide_overflow {
  overflow: hidden;
}

html {
  height: 100vh;
  font-size: 16px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* Smooth scrolling */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

html::-webkit-scrollbar {
  width: 0px;
  /* Safari and Chrome */
  background: transparent;
}


.view_cursor {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: none;
  position: fixed;
  background: var(--color13);
  color: var(--color4);
  font-size: 18px;
  font-weight: 600;
  border-radius: 50%;
  height: 0;
  width: 0;
  display: none;
  transition: height 0.3s ease, width 0.3s ease;
  overflow: hidden;
}

.container-data {
  padding: 0 160px;
}

.container-data-small {
  padding: 0 90px;
}

@media (max-width: 1420px) {
  .container-data {
    padding: 0 90px;
  }
}

@media (min-width: 360px) and (max-width: 480px) {

  .container-data,
  .container-data-small {
    padding: 0 20px;
  }

  .homepage_my_work_container {
    margin-top: 80px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .container-data {
    padding: 0 40px;
  }

  .container-data-small {
    padding: 0 40px;
  }
}