.work_container {
  scale: 0.5;
  width: 1000px;
  height: 505px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #0000001A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.work_title {
  padding: 40px 20px;
  font-family: var(--Inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 24.72px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}

.work_desc_conatiner {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.work_right_section {
  margin-top: 20px;
  padding-right: 40px;
  font-family: var(--Manrope);
  text-align: left;
  width: 50%;
}

.work_name {
  font-size: 36px;
  font-weight: 600;
  line-height: 55.62px;
  letter-spacing: 0.02em;
}

.work_desc {
  margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
}

.work_logo_container {
  position: absolute;
}

@media (min-width: 360px) and (max-width: 480px) {
  .work_container {
    width: 100%;
    scale: unset;
    /* padding: 20px; */
    padding-bottom: 0px;
    height: max-content;
  }

  .work_title {
    padding: 20px 20px 0 20px !important;
    line-height: 21.63px;
    letter-spacing: 0.02px;
    padding: 0;
  }

  .work_desc_conatiner {
    margin-top: 50px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .work_right_section {
    width: 100%;
    padding: 0 20px;
    font-family: var(--Inter);
  }

  .work_name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21.63px;
    letter-spacing: 0.02px;
  }

  .work_desc {
    font-family: var(--Manrope);
    font-size: 14px;
    line-height: 21.63px;
    letter-spacing: 0.0px;

  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .work_container {
    width: 100%;
    scale: unset;
    /* padding: 20px; */
    padding-bottom: 0px;
    height: max-content;
  }
}