.footer_getintouch_container {
  position: relative;
}

.footer_getintouch_container :hover {
  cursor: pointer;
}

.footer_getintouch_border {
  width: 90%;
  position: absolute;
  top: 100px;
  height: 1px;
  background: #FFFFFF3D;
}

.footer_getintouch_border_color {
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.zIndex {
  position: relative;
  z-index: 2;
}

@media (min-width: 360px) and (max-width: 835px) {
  .footer_getintouch_border {
    width: 80%;
    top: 50%;
  }

  .footer_getintouch_container {
    margin-top: 40px;
  }
}