.homepage_bg_image {
  background-image: url("../../assets/new-images/bg_logo_home.png");
  height: 550px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 550px;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: space-between;
}

.home_content {
  padding-top: 120px;
}

.logo_main {
  position: relative;
  height: 100%;
  right: -80px;
  display: flex;
  align-items: center;

  img {
    height: 330px;
  }
}

.margin_top {
  margin-top: 80px;
}

.homegape_color {
  color: var(--color);
  font-family: var(--Manrope);
  line-height: 49.44px;
  text-align: left;
  font-size: 32px;
  width: 638px;
}

.name {
  font-weight: 400;
}

.owner_name {
  font-weight: 500;
}

.hompepage_exp_text {
  color: var(--color);
  font-family: var(--Manrope);
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 154.5%;
  /* 37.08px */
}

.work_exp {
  margin-top: 16px;
  width: 80%;
  font-size: 20px;
  font-weight: 300;
  line-height: 32.2px;
  text-align: left;
  color: var(--color);
  font-family: var(--Manrope);
}

.work_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.homepage_exe_yr {
  width: 690px;
  margin-right: 130px;
  padding: 10px;
}

.homepage_exe_weight {
  font-weight: 500;
}

.homepage_exe_color {
  color: var(--color);
  font-family: var(--Manrope);
}

.home_exe_yr_rignt {
  width: 450px;
  height: 120px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.72px;
  text-align: left;
}

.msg_container {
  width: 488px;
  margin-top: 16px;
}

.msg_desc {
  font-family: var(--Manrope);
  font-size: 18px;
  line-height: 28.98px;
  text-align: left;
}

.msg_desc_color {
  color: var(--color);
  font-weight: 400;
}

.msg_desc_company {
  color: var(--color3);
  font-weight: 600;
}

.home_work_ex {
  font-weight: 300;
  color: var(--color);
}

.logo_height {
  height: 348px;
}

.homepage_about {
  width: 100%;
  height: 200px;
  margin-top: 40px;
  font-family: var(--Manrope);
  position: relative;
  right: 15%;
  color: var(--color4);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.homepage_work_list_container {
  margin-top: 30px;
}

.hompage_project_name {
  width: 100%;
  height: 120px;
  align-items: center;
}


.circleButton {
  font-size: var(--Manrope);
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color13);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color4);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.circle_view {
  transform: scale(0.12);
}

.hompage_project_title {
  width: 400px;
  font-size: 24px;
  font-weight: 600;
  line-height: 38.64px;
  letter-spacing: 0.065em;
  text-align: left;
  color: var(--color);
}

.hompage_project_domain {
  font-size: 18px;
  font-weight: 400;
  line-height: 28.98px;
  letter-spacing: 0.03em;
  text-align: right;
  color: var(--color);
  padding-right: 46px;
}

.homepage_project_border {
  border-bottom: 1.5px solid var(--color6);
}


.homepage_project_border_pd {
  padding-bottom: 40px;
  border-bottom: 1.5px solid var(--color6);
}

.homepage_project_desc {
  width: 440px;
  font-family: var(--Helvetica_Neue);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.homepage_project_desc2 {
  width: 440px;
  font-family: var(--Inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}


.homepage_project_bg_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 534px;
  height: 319px;
  border-radius: 10px;
}

.home_project_img {
  width: 315px;
  height: 200px;
}

.homepage_tools_container {
  margin-top: 60px;
}

.homepage_tool_title {
  font-family: var(--Inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
}

.project:hover {
  cursor: pointer;
}

.homepage_exe_duration {
  font-family: var(--Manrope);
  font-size: 16px;
  font-weight: 500;
  line-height: 25.76px;
  color: var(--color);
  text-align: left;
}

.homepage_have_a_project_conatiner {
  margin-top: 90px;
  background-color: var(--color)
}

.homepage_have_a_project_bg {
  padding-bottom: 200px;
  background-color: var(--color4);
  border-radius: 0 0 100px 100px;
}

.homepage_have_a_project_container2 {
  width: 715px;
}

.homepage_my_work_title {
  font-family: var(--Manrope);
  color: var(--color5);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.4px;
  width: 1000px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.work_section_margin {
  margin-top: 56px;
}

.exp_work_section_margin {
  margin-top: 160px;
}

.work_section_margin1 {
  margin-top: 120px;
}

.project_desc_container {
  height: 340px;
  display: none;
  overflow: hidden;
  margin-bottom: 30px;
}

.homepage_have_a_project_desc {
  margin-top: 56px;
  font-family: var(--Manrope);
  font-size: 20px;
  font-weight: 500;
  line-height: 34.8px;
  text-align: left;
  color: var(--color);
}

.tools_container_margin {
  margin-top: 160px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .homepage_bg_image {
    width: 100%;
    background-image: none;
    height: max-content;
    margin-top: 80px;
  }

  .logo_main,
  .hide_overflow {
    display: none;
  }

  .homegape_color {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 30.9px;
  }

  .work_exp {
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 22.54px;
    letter-spacing: 0.0px;
  }

  .homepage_my_work_title {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 21.63px;
    letter-spacing: 0.02px;
  }

  .work_section_margin {
    margin-top: 40px;
  }

  .work_section {
    width: 100%;
  }

  .exp_work_section_margin {
    margin-top: 80px;
  }

  .tools_container_margin {
    margin-top: 80px;
  }

  .homepage_have_a_project_bg {
    padding-bottom: 30px;
    border-radius: 0 0 40px 40px;
  }

}

@media (min-width: 481px) and (max-width: 1024px) {
  .homepage_bg_image {
    background-size: 270px;
    height: 300px;
    margin-top: 0px;
  }

  .logo_main {
    right: 0;

    img {
      height: 165px
    }
  }

  .homegape_color {
    font-weight: 400;
    font-size: 24px;
    width: 338px;
  }

  .work_exp {
    font-weight: 300;
    font-size: 16px;
    width: 338px;
  }

  .homepage_my_work_title {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 21.63px;
    letter-spacing: 0.02px;
  }

  .homepage_have_a_project_bg {
    padding-bottom: 80px;
    border-radius: 0px 0px 80px 80px;
  }
}