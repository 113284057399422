.topContainer {
  margin-right: 120px;
  margin-left: 160px;
  margin-top: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 64px;
  font-weight: 500;
  color: #181818;
  font-family: var(--Manrope);
}

.tagContainer {
  display: flex;
}

.tag {
  padding: 8px 16px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #D9D9D9;
  color: #1E1E1E;
  margin-right: 16px;
  font-family: var(--Manrope);
}

.tag2 {
  padding: 8px 16px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #D9D9D9;
  color: #1E1E1E;
  margin-right: 16px;
  font-family: var(--Manrope);
  margin-top: 18px;
}

.tag:last-child {
  margin-right: 0;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
  padding-left: 160px;
  padding-right: 120px;
}

.containerSection3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
}

.section3LeftContainer {
  width: 50%;
}

.section3GoalDescription {
  font-size: 24px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 24px;
  width: 75%;
  font-family: var(--Manrope);
}

.section3RightContainer {
  width: 50%;
  padding-left: 50px;
}

.section3ToolsContainer {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
}

.section3Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
  padding-left: 80px;
  padding-right: 117px;
}

.section3ProblemStatementContainer {
  width: 65%;
}

.sectionTitle {
  font-weight: 500;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;
  line-height: 24.36px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: var(--Manrope);
}

.section3ProblemStatementContent {
  margin-top: 20px;
  width: 506px;
}

.section_circle {
  margin: 0 10px 0 0;
  font-size: 18px;
  margin-top: -5px;
}

.section3ProblemPoint {
  display: flex;
  font-size: 18px;
  color: #0B0B0B;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--Inter);
}

.section3ChallengeContainer {
  width: 50%;
}

.section3ChallengeContent {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
}

.section3ChallengeText {
  font-size: 28px;
  color: #1E1E1E;
  font-weight: 400;
  margin-top: 20px;
  font-family: var(--Manrope);
  line-height: 39.2px;
  text-align: left;
}


.section3ChallengeTextBold {
  font-size: 28px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 20px;
  font-family: var(--Manrope);
}


.item {
  width: 351px;
}

.line {
  width: 351px;
  height: 1px;
  background-color: #1818184D;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 21px;
  margin-bottom: 40px;
}

.paragraph {
  width: 351px;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  font-family: var(--Inter);
}

.paragraphTwo {
  width: 351px;
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 500;
  font-family: var(--Inter);
}


.circle {
  width: 200px;
  height: 200px;
  background-color: #4A5BE1;
  border-radius: 50%;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: var(--Manrope);
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 1;
  right: 120px;
  bottom: -100px;
}

.main {
  position: relative;
  background: #FBF5F1;
}

.blueDiv {
  height: 640px;
  width: 100%;
  padding-bottom: 140px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.blueDiv img {
  height: 100%;
  object-fit: contain;
}

.thought {
  width: 100%;
  height: auto;
}

.arrowContainer {
  margin-top: 80px;

  span {
    display: block;
    margin-bottom: 40px;
  }
}

.leftArrow {
  width: 50px;
  height: 2px;
  background-color: black;
  position: relative;
  margin-right: 10px;
}

.leftArrow::before {
  content: '';
  width: 0;
  height: 0;
  border-right: 10px solid black;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -4px;
  left: -10px;
}

.box {
  color: #0B0B0B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 16px 75px;
  border: 1px solid #0B0B0B;
}

.arrow {
  width: 12%;
  height: 2px;
  background-color: black;
  position: relative;
  margin-right: 10px;
}

.arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid black;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -4px;
  right: -10px;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 160px;
  padding-right: 80px;
  justify-content: space-between;
}

.listItem {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.listItem1 {
  display: flex;
  align-items: center;
  /* margin: 5px; */
  flex: 1 1 calc(25% - 20px);
  /* Adjust width and account for gap */
  min-width: 200px;
  /* Prevents items from shrinking too much */
  box-sizing: border-box;
}

.bullet {
  font-size: 24px;
  margin-right: 5px;
}

.text {
  font-size: 18px;
  color: #0B0B0B;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .listContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .listItem {
    flex: 1 1 calc(50% - 20px);
    /* 2 items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .listItem {
    flex: 1 1 100%;
    /* 1 item per row on very small screens */
  }
}

.section4Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 119px;
  padding-bottom: 40px;
}

.section4SolutionContainer {
  width: 50%;
  margin-top: 45px;
}

.section4SolutionContent {
  margin-top: 20px;
  width: 506px;
}

.section4SolutionText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  font-family: var(--Manrope);
}

.section4ImpactText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  margin-top: 52px;
  font-family: var(--Manrope);
}

.section4ImageContainer {
  width: 50%;
  margin-top: 50px;
  display: contents;
}

.section5Container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.section5ContainerClass {
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  background-color: var(--color20);
}

.design_outcome_caontainer {
  padding-top: 80px;
}

.section5DesignOutcomesTitle {
  font-weight: 500;
  font-size: 14px;
  color: #3E3E3E;
  text-transform: uppercase;
  font-family: var(--Manrope);
  display: block;
  margin-bottom: 20px;
}

.section5ContentContainer {
  margin-top: 120px;
}

.section5ContentContainerOne {
  display: flex;
  width: 100%;
}

.section5TextContainer {
  margin-top: 40px;
  width: 510px;
  margin-left: 80px;
}

.section5HairomaTitle,
.section5ChooseScentTitle,
.section5CheckoutTitle {
  font-weight: 600;
  font-size: 14px;
  color: #1E1E1E;
  text-transform: uppercase;
  font-family: var(--Manrope);
}

.section5TextContent {
  margin-top: 24px;
}

.section5Text {
  font-size: 16px;
  color: var(--color2);
  ;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--Inter);
}



.section9Container {
  background-color: var(--color20);
  padding-left: 80px;
  padding-right: 93px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.section9Content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
}

.section9ImageBlock {
  width: 50%;
}

.section9Image {
  width: 524px !important;
  height: 334px !important;
  object-fit: cover;
}

.section9TextBlock {
  margin-top: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.section9Text {
  font-size: 20px;
  color: #000000;
  font-weight: 400;
  width: 90%;
  font-family: var(--Manrope);
}

.section10Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.section10ContainerMain2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: 120px;
  background-color: #ffffff;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.section10LeftContent {
  width: 523px;
}

.section10Heading {
  font-weight: 500;
  font-size: 14px;
  color: #8d8e8f;
  font-family: var(--Manrope);
}

.section10DescriptionContainer {
  margin-top: 33px;
  width: 100%;
}

.section10Item {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 16px;
}

.section10Text {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 500;
  margin-left: 5px;
  font-family: var(--Manrope);
}

.section10RightContent {
  display: flex;
  justify-content: center;
  margin-bottom: 89px;
}

.section10Image {
  width: 426px !important;
  height: 410px !important;
  object-fit: cover;
}

.boxgridContainer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.boxgridLeft {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: space-between;
  padding-right: 10%;
}

.boxgridRight {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  row-gap: 5%;
  column-gap: 3%;
  width: 58%;
  height: max-content;
}

.boxgridRectangle {
  height: 328px;
  margin-bottom: 20px;
  border: 3px solid #1E1E1E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxgridSquare {
  width: 261px;
  height: 120px;
  border: 3px solid #1E1E1E;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.boxgridParagraph {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  color: #1E1E1E;
  margin-bottom: 16px;
  margin-left: 10px;
  font-family: var(--Manrope);
}

.boxgridParagraphBold {
  font-size: 18px;
  font-weight: 600;
  color: #1E1E1E;
  font-family: var(--Manrope);
}

.boxgridPercent {
  font-size: 32px;
  font-weight: 700;
  color: #1E1E1E;
  line-height: 44.8px;
  font-family: var(--Manrope);
}

.boxgridDesc {
  color: #1E1E1E;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  margin-top: 24px;
  text-align: center;
  font-family: var(--Manrope);
}

.boxgridTitle {
  font-size: 14px;
  font-weight: 500;
  color: #8D8E8F;
  display: flex;
  text-transform: uppercase;
  font-family: var(--Manrope);
}

.UserJourneyMap {
  width: 100%;
  height: auto;
  /* padding-left: 39px;
  padding-right: 39px; */
}

.hair_food_margin_top {
  margin-top: 120px;
}

.UserJourneyTextLink {
  font-size: 14px;
  font-weight: 600;
  color: #1E1E1E;
  text-decoration: underline;
  /* text-underline-position: under; */
  font-family: var(--Manrope);
}

.UserJourneyTextLinkConatiner {
  margin-right: 77px;
  display: flex;
  justify-content: flex-end;
}

.arrowClass {
  position: relative;

  img {
    position: absolute;
    top: -75px;
    left: 100px;
  }
}

.section9FirstTitle {
  margin-top: 80px;
  font-size: 14px;
  font-weight: 500;
  color: #8D8E8F;
  display: flex;
  font-family: var(--Manrope);
}

.uppercaseText {
  text-transform: uppercase;
}

.sectionReasarchContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
}

.sectionReasarchText {
  font-size: 24px;
  color: var(--color2);
  font-weight: 500;
  margin-top: 20px;
  font-family: var(--Manrope);
}


.sectionResearchPoint {
  font-size: 18px;
  color: var(--color2);
  display: flex;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--Inter);
}

.section10ContainerMain {
  background: var(--color);
}


@media (min-width: 360px) and (max-width: 480px) {

  .blueDiv {
    height: 153px;
    width: 100%;
    padding: 0 20px;
  }

  .main {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .circle {
    width: 110px;
    height: 110px;
    font-weight: 600;
    font-size: 14px;
    z-index: 1;
    right: 20px;
    bottom: -56px;
  }

  .title {
    font-size: 32px;
    line-height: 43.71px;
  }

  .topContainer {
    margin: 60px 0px 0 0px;
    padding: 0 20px;
  }

  .tagContainer {
    display: none;
  }

  .container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding: 0 20px;

  }

  .containerSection3 {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 80px;
  }

  .section3RightContainer {
    padding-left: 0px;
  }

  .section3ProblemStatementContainer,
  .section3ProblemStatementContent,
  .section4SolutionContent,
  .section4SolutionContainer,
  .section3RightContainer,
  .section3LeftContainer,
  .section3ChallengeContainer,
  .section3GoalDescription,
  .section9ImageBlock,
  .section10LeftContent,
  .item,
  .line,
  .paragraphTwo,
  .paragraph {
    width: 100%;
  }

  .section3ChallengeContainer,
  .section3RightContainer {
    margin-top: 40px;
  }

  .line {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  .item {
    .sectionTitle {
      margin-top: 40px;
    }
  }

  .sectionResearchPoint {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section5Text {
    font-size: 14px;
  }

  .paragraph {
    font-size: 18px;
    line-height: 25.2px;
    width: 100%;
  }


  .section3ChallengeText,
  .section3ChallengeTextBold {
    font-size: 18px;
    line-height: 25.2px;
  }

  .section4Container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 80px;
    padding-bottom: 0px;
  }

  .section4SolutionContainer {
    margin-top: 0px;
  }

  .section4SolutionContent {
    margin-top: 20px;
  }

  .section4ImageContainer {
    width: 100%;
    margin-top: 50px;
    display: block;
  }

  .section5ContainerClass,
  .section5ContentContainerOne {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 100%;
  }

  .section5TextContainer {
    margin-top: 40px;
    width: 100%;
    margin-left: 0px;
  }

  .section5ContentContainer {
    margin-top: 80px;
  }

  .sectionReasarchContainer {
    display: grid;
    grid-template-columns: 100%;
  }

  .sectionReasarchText {
    margin-top: 20px;
    font-size: 18px;
    line-height: 25.2px;
  }

  .hair_food_margin_top {
    margin-top: 80px;
  }

  .boxgridContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100%;
  }

  .boxgridLeft {
    width: 100%;
    padding-right: 0;
  }

  .boxgridRight {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    width: 100%;
  }

  .boxgridSquare {
    width: 100%;
  }

  .boxgridPercent {
    font-size: 24px;
    line-height: 33.6px;
  }

  .userJourneyMapTitle {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .boxgridDesc {
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
  }

  .UserJourneyTextLinkConatiner {
    margin-right: 0px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .section9FirstTitle {
    margin-top: 60px;
  }

  .section9Container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 100%;
  }

  .section9Image {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  .section9Content {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-top: 40px;
  }

  .arrowClass {
    position: relative;

    img {
      position: absolute;
      top: -100px;
      left: 40px;
    }
  }

  .section9Text {
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    line-height: 19.6px;
  }

  .section9TextBlock {
    margin-top: 30px;
  }

  .section9ImageBlockMargin {
    margin-top: 20px;
  }

  .section10Container {
    padding-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }

  .section10ContainerMain2 {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    padding-bottom: 80px;
  }

  .section10RightContent {
    margin-bottom: 0;
  }

  .section10Text {
    font-size: 16px;
    line-height: 24.32px;
  }

  .section3GoalDescription {
    font-size: 18px;
    line-height: 25.2px;
    letter-spacing: 0%;
  }

  .section10Image {
    width: 100% !important;
    height: auto !important;
  }
}


@media (min-width: 481px) and (max-width: 1024px) {

  .blueDiv {
    height: 360px;
    width: 100%;
    padding: 0 40px;
  }

  .main {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .circle {
    width: 160px;
    height: 160px;
    font-weight: 600;
    font-size: 16px;
    z-index: 1;
    right: 120px;
    bottom: -80px;
  }

  .title {
    font-size: 64px;
    line-height: 43.71px;
  }

  .topContainer {
    margin: 60px 0px 0 0px;
    padding: 0 40px;
  }

  .tagContainer {
    display: none;
  }

  .container {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding: 0 40px;

  }

  .containerSection3 {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 80px;
  }

  .section3RightContainer {
    padding-left: 0px;
  }

  .section3ProblemStatementContainer,
  .section3ProblemStatementContent,
  .section4SolutionContent,
  .section4SolutionContainer,
  .section3RightContainer,
  .section3LeftContainer,
  .section3ChallengeContainer,
  .section3GoalDescription,
  .section9ImageBlock,
  .section10LeftContent,
  .item,
  .line,
  .paragraphTwo,
  .paragraph {
    width: 100%;
  }

  .section3ChallengeContainer,
  .section3RightContainer {
    margin-top: 0px;
  }

  .line {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  .item {
    .sectionTitle {
      margin-top: 40px;
    }
  }

  .sectionResearchPoint {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section5Text {
    font-size: 14px;
  }

  .paragraph {
    font-size: 18px;
    line-height: 25.2px;
    width: 100%;
  }


  .section3ChallengeText,
  .section3ChallengeTextBold {
    font-size: 18px;
    line-height: 25.2px;
  }

  .section4Container {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 80px;
    padding-bottom: 0px;
  }

  .section4SolutionContainer {
    margin-top: 0px;
  }

  .section4SolutionContent {
    margin-top: 20px;
  }

  .section4ImageContainer {
    width: 100%;
    margin-top: 50px;
    display: block;

    video {
      width: 100%;
      height: auto;
    }
  }

  .section5ContentContainerOne {
    padding-bottom: 30px;
    display: grid;
    gap: 9%;
    grid-template-columns: 40% 50%;

    video {
      width: 100%;
      height: auto;
    }
  }

  .section5TextContainer {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
  }

  .section5ContentContainer {
    margin-top: 80px;
  }

  .sectionReasarchContainer {
    display: grid;
    grid-template-columns: 47% 47%;
  }

  .sectionReasarchText {
    margin-top: 20px;
    font-size: 18px;
    line-height: 25.2px;
  }

  .hair_food_margin_top {
    margin-top: 80px;
  }

  .boxgridContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 47% 47%;
  }

  .boxgridLeft {
    width: 100%;
    padding-right: 0;
  }

  .boxgridRight {
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    width: 100%;
  }

  .boxgridSquare {
    width: 100%;
  }

  .boxgridPercent {
    font-size: 24px;
    line-height: 33.6px;
  }

  .userJourneyMapTitle {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .boxgridDesc {
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
  }

  .UserJourneyTextLinkConatiner {
    margin-right: 0px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .section9FirstTitle {
    margin-top: 60px;
  }

  .section9Container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 100%;
  }

  .section9Image {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  .section9Content {
    display: grid;
    grid-template-columns: 45% 45%;
    width: 100%;
    margin-top: 40px;
  }

  .arrowClass {
    position: relative;

    img {
      position: absolute;
      top: -100px;
      left: 40px;
    }
  }

  .section9Text {
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    line-height: 19.6px;
  }

  .section9TextBlock {
    margin-top: 30px;
  }

  .section9ImageBlockMargin {
    margin-top: 0px;
  }

  .section10ContainerMain2 {
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    padding-bottom: 120px;
  }

  .section10RightContent {
    margin-bottom: 0;
  }

  .section10Text {
    font-size: 16px;
    line-height: 24.32px;
  }

  .section3GoalDescription {
    font-size: 18px;
    line-height: 25.2px;
    letter-spacing: 0%;
  }

  .section10Image {
    width: 100% !important;
    height: auto !important;
  }
}