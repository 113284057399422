@keyframes animatedDot1 {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }
}

.available {
  color: var(--color12);
  font-weight: 600;
  animation: animatedDot1 1.8s ease-in-out infinite;
}

.homepage_have_a_project_email {
  font-weight: 400;
  text-decoration: underline;
}