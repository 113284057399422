.topContainer {
  margin-top: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .calorie_wise_container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.calorie_wise_product {
  color: var(--color);
  font-family: var(--Manrope);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  height: 38px;
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid var(--color15);
}

.title {
  font-size: 64px;
  font-weight: 500;
  color: #1C1D20;
  font-family: var(--Manrope);
}

.tagContainer {
  display: flex;
}

.tag {
  padding: 8px 16px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #D9D9D9;
  color: #1E1E1E;
  margin-right: 16px;
}

.tag:last-child {
  margin-right: 0;
}



.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
}

.containerSection3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 190px;
}

.section3LeftContainer {
  width: 55%;
}

/* .section3GoalText {
  font-weight: 500;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;
  font-family: var(--Manrope);
} */

.link {
  font-size: 24px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 24px;
  margin-top: 16px;
  text-decoration: underline;
  font-family: var(--Manrope);
}

.section3GoalDescription {
  font-size: 24px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 24px;
  width: 90%;
  font-family: var(--Manrope);
  font-family: Manrope;
  line-height: 33.6px;
  text-align: left;
}

.section3RightContainer {
  width: 40%;
}

/* .section3ToolsText {
  font-weight: 600;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;
  font-family: var(--Manrope);
} */

.section3ToolsContainer {
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 10px;
}

.section3Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
}

.section3ProblemStatementContainer {
  width: 60%;
}

/* .section3ProblemStatementTitle {
  font-weight: 600;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;

  font-family: var(--Manrope);
} */

.section3ProblemStatementContent {
  margin-top: 20px;
  width: 506px;
}

.section3ProblemPoint {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--Inter);
  display: flex;
}

.section3ProblemPointBold {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 16px;
  font-family: var(--Inter);
}

.section3ChallengeContainer {
  width: 40%;
}

.section3ChallengeContent {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
}

.section3ChallengeText {
  font-size: 24px;
  width: 532px;
  color: #1E1E1E;
  font-weight: 500;
  margin-top: 20px;
  font-family: var(--Manrope);
}

.item {
  width: 351px;
}

.itemText {
  font-weight: 600;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;
  font-family: var(--Manrope);
  font-family: Manrope;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
}

.line {
  width: 351px;
  height: 1px;
  background-color: #1818184D;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 21px;
  margin-bottom: 40px;
}

.paragraph {
  width: 351px;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  font-family: var(--Inter);
  font-family: Inter;
  line-height: 22.4px;
  text-align: left;
}


.circle {
  width: 200px;
  height: 200px;
  background-color: #4A5BE1;
  border-radius: 50%;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 1;
  right: 120px;
  bottom: -100px;
  font-family: var(--Manrope);
}

.main {
  position: relative;
  /* padding-top: 220px; */
}

.blueDiv {
  width: 100%;
  padding-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #F9F9F9;
}

.imageContainer {
  height: 640px;
}

.imageContainer img {
  height: 100%;
  object-fit: contain;
}

.arrowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 190px;
  padding-left: 80px;
  padding-right: 80px;
}

.leftArrow {
  width: 50px;
  height: 2px;
  background-color: black;
  position: relative;
  margin-right: 10px;
}

.leftArrow::before {
  content: '';
  width: 0;
  height: 0;
  border-right: 10px solid black;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -4px;
  left: -10px;
}

.box {
  color: #1E1E1E;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 16px 75px;
  border: 1px solid #1E1E1E;
}

.arrow {
  width: 12%;
  height: 2px;
  background-color: black;
  position: relative;
  margin-right: 10px;
}

.arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid black;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -4px;
  right: -10px;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 160px;
  padding-right: 80px;
  justify-content: space-between;
}

.listItem {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.listItem1 {
  display: flex;
  align-items: center;
  /* margin: 5px; */
  flex: 1 1 calc(25% - 20px);
  /* Adjust width and account for gap */
  min-width: 200px;
  /* Prevents items from shrinking too much */
  box-sizing: border-box;
}

.bullet {
  font-size: 24px;
  margin-right: 5px;
}

.text {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .listContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .listItem {
    flex: 1 1 calc(50% - 20px);
    /* 2 items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .listItem {
    flex: 1 1 100%;
    /* 1 item per row on very small screens */
  }
}


.section4Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 143px;
  padding-bottom: 120px;
}

.section4SolutionContainer {
  width: 50%;
}

.section_padding_left {
  padding-left: 80px;
}

/* 
.section4SolutionTitle {
  color: #8D8E8F;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--Manrope);
} */

.section4SolutionContent {
  margin-top: 20px;
  width: 380px;
}

.section4SolutionText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  font-family: var(--Inter);
}

.section4ImpactText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  margin-top: 52px;
  font-family: var(--Inter);
}

.section4SolutionTextBold {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 500;
  font-family: var(--Inter);
}

.section4ImageContainer {
  width: 50%;
  margin-top: 50px;
  display: contents;
}


.section4Image {
  object-fit: cover;
}

.section4Image3 {
  height: 324px;
  object-fit: cover;
}

.section4Image1 {
  height: 465px;
  object-fit: cover;
}

.section4Image2 {
  height: 582px;
  object-fit: cover;
}

.sectionDesignContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 56px;
}

.sectionDesignContainerSubConainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sectionDesignContainer1 {
  margin-top: 120px;
}

.section_margin_top {
  margin-top: 120px;
}


.sectionBoxContainer {
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  margin-top: 160px;
  padding-left: 80px;
  padding-right: 80px;
  /* padding-bottom: 160px; */
}



/* .sectionDesignTitle {
  color: #8D8E8F;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: var(--Manrope);
} */

/* .sectionHookedTitle {
  color: #8D8E8F;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--Manrope);
} */

/* .sectionDesginContent {
  margin-top: 24px;
} */

.sectionHookedContent {
  margin-top: 40px;
}

.sectionHookedContentWidth {
  width: 524px;
}

.sectionDesignText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  font-family: var(--Inter);
  line-height: 25.2px;
  letter-spacing: 0.04em;
  text-align: left;
}

.sectionHookedText {
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  margin-bottom: 40px;
  /* padding-right: 40px; */
  font-family: var(--Inter);
  line-height: 25.2px;
  text-align: left;
}

.boxedRowContainer {
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
  margin-top: 80px;

}

.boxedBox {
  width: 413px;
  height: 340px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(30, 30, 30, 0.20);
  padding: 40px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.boxedTitle {
  font-size: 28px;
  /* margin-top: 40px; */
  margin-bottom: 38px;
  /* margin-left: 24px; */
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 28px;
  font-weight: 500;
}

.boxedParagraph {
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 24px;
  font-weight: 400;
  /* margin-left: 24px; */
}

.sectionDesignImageContainer {
  display: flex;
  flex-direction: column;
}

.sectionHookedImageContainer {
  display: flex;
  flex-direction: column;
}

.section5Container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.section5DesignOutcomesTitle {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  color: #8D8E8F;
  font-family: var(--Manrope);
  text-transform: uppercase;
}

.section5ContentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 60px 212px 60px 177px;
  position: relative;
  background-color: var(--color20);
}

.paddign_bottom {
  padding-bottom: 80px;
}

.section5Image {
  object-fit: cover;
  flex: 1;
  height: 480px;
}

.section5TextContainer {
  flex: 1;
  /* Added flex property to manage width */
  padding-left: 171px;
  /* Optional: Adjust padding for spacing */
}

.section5HairomaTitle,
.section5ChooseScentTitle,
.section5CheckoutTitle {
  font-family: var(--Manrope);
  font-size: 14px;
  font-weight: 500;
  line-height: 24.36px;
  letter-spacing: 0.04em;
  color: var(--color);
  text-transform: uppercase;
}

.section5TextContent {
  margin-top: 24px;
}

.section5Text {
  font-size: 20px;
  color: var(--color2);
  font-weight: 400;
  margin-top: 16px;
  word-wrap: break-word;
  line-height: 1.5;
  font-family: var(--Inter);
}


.section9Title {
  font-weight: 500;
  font-size: 14px;
  color: #1E1E1E;
  margin-top: 117px;
}

.section9FeedbackContainer {
  background-color: var(--color20);
  padding-left: 80px;
  padding-right: 93px;
  display: flex;
  flex-direction: column;
}

.section9FeedbackContainer .section9Title {
  margin-top: 428px;
}

.section9FeedbackContainer {
  margin-top: 128px;
}

.section9FeedbackContainer {
  margin-bottom: 171px;
}

.section10Heading {
  font-weight: 600;
  font-size: 14px;
  color: #8d8e8f;
}

.section10DescriptionContainer {
  margin-top: 33px;
  width: 669px;
}

.section10Item {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 16px;
}

.boxgridRectangle {
  height: 328px;
  margin-bottom: 20px;
  border: 3px solid #1E1E1E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxgridRectangleWithouthBorder {
  height: 328px;
  margin-bottom: 20px;
}

.boxgridParagraph {
  font-size: 20px;
  font-weight: 400;
  color: #1E1E1E;
  margin-bottom: 16px;
  margin-left: 10px;
  font-family: var(--Manrope);
}

.boxgridParagraphBold {
  font-size: 20px;
  font-weight: 600;
  color: #1E1E1E;
  font-family: var(--Manrope);
}

.boxgridTitle {
  font-size: 14px;
  font-weight: 500;
  color: #8D8E8F;
  margin-top: 160px;
  margin-bottom: 24px;
  margin-left: 81px;
  display: flex;
  text-transform: uppercase;
  font-family: var(--Manrope);
}


.boxgridTitle {
  font-size: 14px;
  font-weight: 500;
  color: #8D8E8F;
  margin-top: 120px;
  margin-bottom: 24px;

  display: flex;
  text-transform: uppercase;
  font-family: var(--Manrope);
}


.UserJourneyMap {
  width: 100%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.UserJourneyTextLink {
  font-size: 14px;
  font-weight: 600;
  color: #1E1E1E;
  text-decoration: underline;
  /* text-underline-position: under; */
  font-family: var(--Manrope);
}

.sectionReasarchContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
  padding-left: 81px;
  padding-right: 79px;
  margin-bottom: 120px;
}


.sectionResearchPoint {
  font-size: 18px;
  color: var(--color2);
  ;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--Inter);
}

.boxedImage {
  width: 388.321px;
  height: 809.215px;
  object-fit: cover;
  /* margin: auto;  */
}

.imageContainerBox {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.boxedImageTop {
  width: 388.321px;
  height: 809.215px;
  object-fit: cover;
  /* margin: auto;  */
  margin-top: 150px;
}

.fakefrontGrid3 {
  display: flex;
  height: 92%;
  gap: 16px;
}

.fakefrontGrid4 {
  display: flex;
  height: 100%;
  gap: 16px;
}


.fakefrontGrid5 {
  display: flex;
  height: 100%;
  gap: 16px;
  flex-direction: column;
}

.fakefrontBoxMiddle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 5%;
}

.fakefrontBox,
.fakefrontBox2 {
  width: 189px;
  height: 207px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #1e1e1e;
  border-radius: 4px;
  padding: 32px 0;

  .fakefrontContent,
  .fakefrontContent2 {
    text-align: left;
    color: var(--color21);
    font-family: var(--Manrope);
    font-size: 56px;
    font-weight: 700;
    line-height: 97.44px;
    letter-spacing: 0.04em;
  }

  .fakefrontDescription,
  .fakefrontDescription2 {
    font-family: var(--Inter);
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;
  }
}

.fakefrontMaineContent {
  height: 96%;
  margin-top: 24px;
}

.fakefrontMaineContent2 {
  height: 96%;
  margin-top: 52px;
}


.uiScreens {
  color: #8D8E8F;
  font-family: var(--Manrope);
  font-size: 14px;
  display: flex;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 80px;
  margin-bottom: 70px;
  margin-top: 160px;
}

.uiScreensImageGridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.screenUiContent {
  margin-top: 24px;
  width: 420px;
}

.inside_container,
.male_female_container {
  font-family: var(--Inter);
  display: flex;
  align-items: center;
}

.container_number {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.89px;
  text-align: center;
  color: #FF8739;
  margin-right: 25px;
}

.male_female_container {
  margin-top: 40px;
}

.container_participant {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #3C3C3C;
}

.container_participant1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}

.inside_footer_name {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;

}

.screenUiContainer {
  width: 45%;
}

.takeWaysContainer1 {
  padding-bottom: 200px;
  background-color: var(--color4);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.takeWaysContainer2 {
  display: flex;
  justify-content: space-between;
}

.section_circle {
  margin: 0 10px 0 0;
  font-size: 24px;
  margin-top: -5px;
}

.section_circle1 {
  margin: 0 10px 0 0;
  font-size: 18px;
  margin-top: -3px;
}

.takeWaysContainer {
  width: 100%;
  background-color: var(--color);
}

.takeWaysContent {
  width: 524px;
}


.takeWaysInnerContainer {
  width: 65%;
}


.takeAwayPoint {
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 16px;
  display: flex;
  align-items: flex-start;
}


.takewayImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.takewayImage {
  width: 100%;
  height: 348px;
}


.usabilityImageContainer {
  display: flex;
  flex-direction: column;
  padding-right: 120px;
  background: #FF934D33;
  height: 632px;
  margin-top: 24px;
}

.usabilityUiContent {
  margin-top: 24px;
  width: 505px;
}


.usabilitySectionText {
  font-family: var(--Inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #1E1E1E;

  .five_yr {
    font-weight: 500;
  }
}

.inisight2Image {
  width: 100%;
  height: auto;
}

.inisight2Image1 {
  height: 400px;
  width: 100%;
}

.insight2Container {
  height: 100%;
  margin-top: 24px;
}

.up-icon {
  color: white;
  font-size: 24px;
}

.inside_container_link {
  cursor: pointer;
  margin-top: 40px;
  color: #FF8739;
  font-family: var(--Inter);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
}


@media (min-width: 360px) and (max-width: 480px) {

  .blueDiv {
    width: 100%;
    height: 356px;
    padding: 0 20px;
  }

  .imageContainer {
    height: 100%;
    padding: 80px 0 120px 0;
    width: 100%;
  }

  .imageContainer img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .main {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .link {
    font-size: 18px;
  }

  .section3Container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 80px;
  }

  .circle {
    width: 110px;
    height: 110px;
    font-weight: 600;
    font-size: 14px;
    z-index: 1;
    right: 20px;
    bottom: -56px;
  }

  .title {
    font-size: 32px;
    line-height: 43.71px;
  }

  .topContainer {
    margin: 60px 0px 0 0px;
    padding: 0 20px;
  }

  .tagContainer {
    display: none;
  }

  .container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding: 0 20px;

  }

  .containerSection3 {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 80px;
  }

  .section3RightContainer {
    padding-left: 0px;
    margin-top: 40px;
    width: 100%;
  }

  .section3ProblemStatementContainer,
  .section3ProblemStatementContent,
  .section4SolutionContent,
  .section4SolutionContainer,
  .section3LeftContainer,
  .section3ChallengeContainer,
  .section3GoalDescription,
  .item,
  .line,
  .paragraph,
  .sectionHookedContentWidth,
  .takeWaysContent,
  .takeWaysInnerContainer {
    width: 100%;
  }


  .line {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  .item {
    .itemText {
      margin-top: 40px;
    }
  }

  .paragraph {
    font-size: 18px;
    line-height: 25.2px;
    width: 100%;
  }

  .section4SolutionText {
    font-size: 16px;
  }

  .sectionDesignText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.04px;
  }

  .section3ChallengeContainer {
    margin-top: 40px;
  }

  .section3ChallengeText {
    font-size: 18px;
    line-height: 25.2px;
  }

  .section4Container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 80px;
    padding-bottom: 0px;
  }

  .section4SolutionContainer {
    margin-top: 0px;
  }

  .section4SolutionContent {
    margin-top: 20px;
  }

  .section4ImageContainer {
    width: 100%;
    margin-top: 50px;
    display: block;

    img {
      width: 100%;
    }
  }

  .section5TextContainer {
    margin-top: 40px;
    width: 100%;
    margin-left: 0px;
    padding-left: 0;
  }

  .section5ContentContainer {
    display: grid;
    grid-template-columns: 100%;
    padding: 80px 40px 50px 20px !important;
    position: relative;
    background-color: var(--color20);

  }

  .sectionHookedImageContainer {
    margin-top: 40px;
  }

  .section4Image1 {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .section5ContentContainer>div>img {
    width: 100%;
    height: auto;
  }

  .section5Text {
    font-size: 16px;
    line-height: 22.4px;
  }

  .sectionDesignContainer {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-top: 20px;
  }

  .container_number {
    margin-right: 25px;
    font-family: Inter;
    font-size: 20px;
    line-height: 24.2px;
  }

  .container_participant {
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
  }

  .sectionDesignContainerSubConainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .sectionDesignContainer1,
  .section_margin_top {
    margin-top: 80px;
  }

  .section_padding_left {
    margin-top: 40px;
    padding-left: 0px;
  }

  .fakefrontGrid3 {
    display: flex;
    height: 245px;
    gap: 16px;
  }

  .fakefrontBox {
    width: 95px;
    min-width: 95px;
    height: 115px;
    padding: 14px;

    .fakefrontContent {
      font-size: 24px;
      line-height: 41.76px;
      letter-spacing: 0.4px;
      text-align: center;
    }

    .fakefrontDescription {
      font-size: 12px;
    }
  }

  .fakefrontBox2 {
    width: 150px;
    height: 170px;
    padding: 45px 0px;

    .fakefrontContent2 {
      font-size: 24px;
      line-height: 41.76px;
      letter-spacing: 0.4px;
      text-align: center;
    }

    .fakefrontDescription2 {
      font-size: 14px;
    }
  }

  .fakefrontGrid4 {
    align-items: center;
    justify-content: space-between;
  }

  .screenUiContainer,
  .screenUiContent {
    width: 100%;
  }

  .fakefrontMaineContent {
    height: auto;
    margin-top: 24px;
    width: 100%;
  }

  .inside_footer_name {
    font-size: 14px;
    text-align: left;
    line-height: 16.94px;
    color: #3C3C3C;
  }

  .usabilityUiContent {
    width: 100%;
  }

  .section4Image2 {
    height: auto;
    object-fit: cover;
  }

  .usabilityImageContainer {
    padding-right: 40px;
    height: 380px;
  }

  .insight2Container {
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .sectionReasarchContainer,
  .takeWaysContainer2 {
    display: grid;
    grid-template-columns: 100%;
  }

  .takeAwayPoint {
    margin-top: 20px;
    margin-left: 0px;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    line-height: 22.4px;
  }

  .takeWaysContainer1 {
    padding-bottom: 80px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    padding-left: 20px !important;
  }

  .takewayImageContainer {
    margin-top: 40px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .sectionHookedText {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.04px;
  }

  .usabilitySectionText {
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.04px;

    .five_yr {
      font-weight: 500;
    }

  }

  .hair_food_margin_top {
    margin-top: 40px;
  }

  .section3GoalDescription {
    font-size: 18px;
    line-height: 25.2px;
    letter-spacing: 0%;
  }

}

@media (min-width: 481px) and (max-width: 1024px) {
  .blueDiv {
    width: 100%;
    height: auto;
    padding: 0 40px;
  }

  .imageContainer {
    height: 100%;
    padding: 20px 0 90px 0;
    width: 100%;
  }

  .imageContainer img {
    width: 100%;
    object-fit: contain;
  }

  .main {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .link {
    font-size: 18px;
  }

  .section3Container {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 80px;
  }

  .circle {
    width: 160px;
    height: 160px;
    font-weight: 600;
    font-size: 16px;
    z-index: 1;
    right: 100px;
    bottom: -100px;
  }

  .title {
    font-size: 64px;
    line-height: 43.71px;
  }

  .topContainer {
    margin: 60px 0px 0 0px;
    padding: 0 40px;
  }

  .tagContainer {
    display: none;
  }

  .fakefrontBox2 {
    width: 150px;
    height: 170px;
    padding: 45px 0px;

    .fakefrontContent2 {
      font-size: 24px;
      line-height: 41.76px;
      letter-spacing: 0.4px;
      text-align: center;
    }

    .fakefrontDescription2 {
      font-size: 14px;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    padding: 0 40px;

  }

  .containerSection3 {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 80px;
  }

  .section3RightContainer {
    padding-left: 0px;
  }

  .takeWaysContainer2 {
    gap: 30px;
  }

  .section3ProblemStatementContainer,
  .section3ProblemStatementContent,
  .section4SolutionContent,
  .section3RightContainer,
  .section3LeftContainer,
  .section3ChallengeContainer,
  .section3GoalDescription,
  .section9ImageBlock,
  .section10LeftContent,
  .item,
  .line,
  .paragraphTwo,
  .takeWaysContent,
  .section4SolutionContainer,
  .paragraph {
    width: 100%;
  }

  .section3ChallengeContainer,
  .section3RightContainer {
    margin-top: 0px;
  }

  .line {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  .item {
    .sectionTitle {
      margin-top: 40px;
    }
  }

  .sectionResearchPoint {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .section5Text {
    font-size: 14px;
  }

  .paragraph {
    font-size: 18px;
    line-height: 25.2px;
    width: 100%;
  }


  .section3ChallengeText,
  .section3ChallengeTextBold {
    font-size: 18px;
    line-height: 25.2px;
  }

  .section4Container {
    display: grid;
    grid-template-columns: 47% 47%;
    margin-top: 80px;
    padding-bottom: 0px;
  }

  .sectionHookedContentWidth {
    width: 100%;
  }

  .section4SolutionContent {
    margin-top: 20px;
  }

  .section4ImageContainer {
    width: 100%;
    margin-top: 50px;
    display: block;

    img {
      width: 100%;
    }
  }


  .section5TextContainer {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
    padding-left: 0;
  }

  .section5ContentContainer {
    display: grid;
    grid-template-columns: 35% 50%;
    padding: 80px 40px 50px 20px !important;
    position: relative;
    background-color: var(--color20);

  }

  .fakefrontMaineContent {
    height: 85%;
    margin-top: 24px;
  }

  .fakefrontBox {
    width: 100px;
    min-width: 95px;
    height: 115px;
    padding: 14px;

    .fakefrontContent {
      font-size: 24px;
      line-height: 41.76px;
      letter-spacing: 0.4px;
      text-align: center;
    }

    .fakefrontDescription {
      font-size: 12px;
    }
  }

  .section5ContentContainer>div>img {
    width: 100%;
    height: auto;
  }

  .sectionReasarchContainer {
    display: grid;
    grid-template-columns: 47% 47%;
  }

  .section4Image1,
  .section4Image {
    width: 340px;
    height: auto;
    object-fit: cover;
    padding-left: 20px;
  }

  .sectionReasarchText {
    margin-top: 20px;
    font-size: 18px;
    line-height: 25.2px;
  }

  .hair_food_margin_top {
    margin-top: 80px;
  }

  .boxgridContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 47% 47%;
  }

  .boxgridLeft {
    width: 100%;
    padding-right: 0;
  }

  .boxgridRight {
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    width: 100%;
  }

  .boxgridSquare {
    width: 100%;
  }

  .boxgridPercent {
    font-size: 24px;
    line-height: 33.6px;
  }

  .userJourneyMapTitle {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .boxgridDesc {
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
  }

  .UserJourneyTextLinkConatiner {
    margin-right: 0px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .section9FirstTitle {
    margin-top: 60px;
  }

  .section9Container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 100%;
  }

  .section9Image {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  .section9Content {
    display: grid;
    grid-template-columns: 45% 45%;
    width: 100%;
    margin-top: 40px;
  }

  .arrowClass {
    position: relative;

    img {
      position: absolute;
      top: -100px;
      left: 40px;
    }
  }

  .section9Text {
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    line-height: 19.6px;
  }

  .section9TextBlock {
    margin-top: 30px;
  }

  .section9ImageBlockMargin {
    margin-top: 0px;
  }

  .section10ContainerMain2 {
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    padding-bottom: 120px;
  }

  .section10RightContent {
    margin-bottom: 0;
  }

  .section10Text {
    font-size: 16px;
    line-height: 24.32px;
  }

  .section3GoalDescription {
    font-size: 18px;
    line-height: 25.2px;
    letter-spacing: 0%;
  }

  .section10Image {
    width: 100% !important;
    height: auto !important;
  }

  .screenUiContainer,
  .screenUiContent {
    width: 100%;
  }

  .usabilityUiContent {
    margin-top: 24px;
    width: 80%;
  }

  .usabilityImageContainer {
    padding-right: 40px;
    height: 380px;

    img {
      width: 100%;
      height: 95%;
    }
  }

  .inisight2Image {
    padding-left: 40px;
  }

  .section4Image3 {
    height: 224px;
    object-fit: cover;
  }

  .takeWaysContainer1 {
    padding-bottom: 80px;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    padding-left: 20px !important;
  }

  .takewayImage {
    width: 100%;
    height: 280px;
  }

}