.DC__blueDiv_BC {
  background: #18233D;
}

.VF__blueDiv_BC {
  background: #32A767;
}

.GB_video_conatiner {
  display: flex;
  flex-direction: column;
  position: relative;
}

.section5ContainerClass {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  background-color: var(--color20);
}

.section5ContentContainerOne {
  display: flex;
  width: 100%;

  video {
    padding: 24px;
    width: 32%;
    height: auto;
    padding-right: 120px;
  }
}

.gb_data_container {
  margin-top: 80px;
}

/* .gb_mv_mr, .vf_mv_mr {
  margin-top: 24px;
} */

.gb_student_status_container {
  margin-top: 24px;
}

.gb_student_status_conatiner_title {
  font-family: var(--Inter);
  font-weight: 400;
  font-size: 18px;
  color: #1E1E1E;
}

.vf_debreifs_mt {
  margin-top: 44px;
}

.GB__blueDiv_BC {
  background: #007AFF;
}

.section5ContentContainer {
  margin-top: 120px;
}

.ZTO__blueDiv_BC {
  background: #D1153B;
}

.section5TextContainer {
  margin-top: 40px;
  width: 100%;
  margin-left: 0px;
}

.section5DesignOutcomesTitle {
  color: #3E3E3E;
  font-family: var(--Manrope);
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.4px;
  letter-spacing: 0.04px;
  text-transform: uppercase;

}

.gb_text_content {
  font-family: var(--Inter);
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.04px;
  color: #1E1E1E;
}

.section5HairomaTitle,
.section5ChooseScentTitle,
.section5CheckoutTitle {
  color: #8D8E8F;
  font-family: var(--Manrope);
  font-weight: 500;
  font-size: 14px;
  line-height: 17.4px;
  letter-spacing: 0.04px;
  text-transform: uppercase;

}

.section5TextContent {
  margin-top: 24px;
}

.section5Text {
  color: #3C3C3C;
  font-family: var(--Inter);
  font-weight: 400;
  font-size: 20px;
}

.vf_main_title {
  font-family: var(--Manrope);
  font-weight: 500;
  font-size: 64px;
  line-height: 100%;
  color: #1C1D20;
}

.blueDiv {
  position: relative;
  padding-bottom: 120px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.blueDiv img {
  width: 100%;
  height: auto;
}

.circle {
  width: 200px;
  height: 200px;
  background-color: #4A5BE1;
  border-radius: 50%;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  position: absolute;
  z-index: 1;
  right: 80px;
  bottom: -100px;
  font-family: var(--Manrope);
}

.image_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  /* margin-top: -65px; */
}

.img_div_odd {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.stepper_container {
  height: 132px;
  display: flex;
  flex-direction: column;
}

.stepper_container_main,
.exp_container_main_1 {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper_name {
  font-family: var(--Manrope);
  font-size: 14px;
  font-weight: 600;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
  color: #8D8E8F;
  text-transform: uppercase;
}

.stepper_line {
  margin: 21px 0 40px 0;
  border: 1px solid #1818184D
}

.vf_stepper_line_width {
  width: 330px;
}

.vf_stepper_desc_width {
  width: 325px;
}

.stepper_line_width {
  width: 251px;
}

.stepper_desc_width {
  width: 245px;

}


.vf_link_text {
  margin-top: 8px;
  font-size: 18px;
  color: #1E1E1E;
  font-weight: 400;
  text-decoration: underline;
  text-underline-position: under;
  font-family: var(--Inter);
  word-break: break-word;
}


.vf_about_vf_container,
.vf_about_vf_container_takeways {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vf_section_mt {
  margin-top: 120px;
}

.vf_section_mt_1 {
  margin-top: 60px;
}

.vf_left,
.vf_right {
  width: 47%;
}

.vf_text_desc_mt {
  margin-top: 24px;
}

.vf_tools_container {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
}

.vf_tool {
  padding: 8px 16px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #D9D9D9;
  color: #1E1E1E;
  margin-right: 16px;
  margin-top: 18px;
}

.vf_paragraph_container {
  display: flex;
}

.vf_paragraph_container_inner_padding {
  padding-left: 20px;
}

.vf_dot {
  margin-top: 8px;
  margin-right: 10px;
  min-height: 7px;
  min-width: 7px;
  max-width: 7px;
  max-height: 7px;
  border-radius: 50%;
  background: #1E1E1E;
}

.vf_main_text {
  font-family: var(--Inter);
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
}

.vf_text {
  font-family: var(--Inter);
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #1E1E1E;
}

.vf_text_desc {
  font-family: var(--Manrope);
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0%;
  color: #1E1E1E;
}

.vf_research_insight_container {
  padding-left: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 65px;
}


.vf_research_insight_image_container {
  display: flex;
  flex-direction: column;
}

.vf_research_insight_small_image {
  height: 128px;
  width: 128px;
}

.vf_research_insight_small_text {
  font-size: 24px;
  font-weight: 400;
  margin-top: 12.98px;
  font-style: italic;
  color: #000000;
  font-family: var(--Helvetica_Neue);
}


.vf_research_insight_paragraph_container {
  display: flex;
  justify-content: flex-end;
}

.vf_research_insight_paragraph {
  font-style: italic;
  font-size: 24px;
  font-weight: 400;
  color: #1E1E1E;
  width: 90%;
  font-family: var(--Inter);
}

.vf_user_flow_container {
  padding: 80px;
  background: #E9E9E9;
}

.gb_logo_image_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.gb_logo_image_container_bg {
  background: #F2F2F2;
  padding-top: 80px;
  padding-bottom: 80px;
}

.gb_logo_image_pd {
  padding: 40px;
}

.gb_testing_insight_font_uc {
  text-transform: uppercase;
}

.gb_testing_insight_font_mt {
  margin-top: 20px;
}

.gb_testing_insight_font {
  font-family: var(--Manrope);
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04px;
  color: #1E1E1E;
}

.vf_logo_image_container_new {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  img {
    width: 45%;
    height: auto;
  }
}

.vf_logo_image_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  img {
    width: 45%;
    height: auto;
  }
}

.vf_user_flow_title {
  font-family: var(--Manrope);
  font-weight: 600;
  font-size: 12px;
  line-height: 174%;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: #8D8E8F;
}

.vf_user_flow_image_container {
  margin-top: 80px;

  img {
    width: 100%;
    height: auto;
  }
}

.stepper_desc {
  font-family: var(--Inter);
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #1E1E1E;
}

.vf_challenge_text {
  font-family: var(--Manrope);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
}

.vf_takeWays_container_main {
  background-color: #1E1E1E;
}

.vf_takeWays_container {
  padding-bottom: 200px;
  background-color: var(--color4);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.exp_container_padding1 {
  padding-bottom: 200px;
}

.exp_container {
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 100px 100px;
}

.exp_container_main {
  margin-top: 80px;
  background: var(--color)
}

.margin_top {
  margin-top: 80px;
}

.exp {
  margin-top: 16px;
  font-family: var(--Manrope);
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
  color: var(--color);
}

.exp_desc {
  margin-top: 32px;
  font-family: var(--Manrope);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: var(--color);
}

.zto_goal_obj_container {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zto_left_container,
.zto_right_container {
  width: 50%;
}

.zto_left_container {
  padding-right: 40px;
}

.zto_right_container {
  padding-left: 40px;
}

.zto_goal_obj_desc {
  margin-top: 24px;
  font-family: var(--Manrope);
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}

.zto_moodboard {
  margin-top: 120px;
}

.zto_moodboard_img_container {
  margin-top: 80px;
}

.zto_img_container_main {
  display: flex;
  justify-content: space-between;
  background: #F2F2F2;
  padding: 80px;
}

.zto_img_container_data {
  width: 523px;
  gap: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
  }
}

.zto_adf_container {
  width: 100%;
  background: #F2F2F2;
  padding: 120px 80px;

  img {
    width: 100%;
  }
}

.vf_logo_image_container_main {
  display: flex;
  align-items: center;

}

.vf_takeways_padding_left {
  padding-left: 80px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .stepper_container_main {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 100%;
  }

  .stepper_line_width {
    width: 251px;
  }

  .stepper_line {
    margin: 20px 0 30px 0;
    border: 1px solid #1818184D
  }

  .stepper_container {
    height: max-content;
    margin-top: 40px;
  }

  .stepper_container_main :first-child {
    margin-top: 0;
  }

  .zto_goal_obj_container {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 100%;
  }

  .zto_goal_obj_desc {
    margin-top: 20px;
    font-size: 18px;
  }

  .zto_left_container,
  .zto_right_container {
    width: 100%;
  }

  .zto_left_container {
    padding-right: 0px;
  }

  .zto_right_container {
    padding-left: 0px;
    margin-top: 40px;
  }

  .zto_moodboard {
    margin-top: 80px;
  }

  .zto_moodboard_img_container {
    margin-top: 20px;
  }

  .section5ContainerClass {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section5ContentContainerOne {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 100%;

    video {
      width: 100%;
      height: auto;
      padding-right: 20px;
    }
  }

  .section5TextContainer {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
  }

  .section5Text {
    font-size: 14px;
  }

  .section5ContentContainer {
    margin-top: 40px;
  }

  .gb_logo_image_container_bg {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .zto_img_container_main {
    padding: 40px 10px;
    display: grid;
    gap: 40px;
    grid-template-columns: 100%;
  }

  .zto_img_container_data {
    width: 100%;
    gap: 40px;
    display: grid;
    grid-template-columns: 100%;
  }

  .exp_desc {
    margin-top: 24px;
    font-size: 18px;
  }

  .zto_adf_container {
    width: 100%;
    background: #F2F2F2;
    padding: 0;

    img {
      width: 100%;
    }
  }

  .exp_container {
    border-radius: 0px 0px 40px 40px;
  }

  .exp_container_padding1 {
    padding-bottom: 80px;
  }

  .exp_container_main {
    margin-top: 20px;
  }


  .image_container {
    padding: 0 10px 0 20px;
  }

  .blueDiv {
    height: auto;
    padding-bottom: 60px;
  }

  .blueDiv img {
    width: 100%;
    height: auto;
  }

  .circle {
    width: 110px;
    height: 110px;
    font-size: 14px;
    right: 40px;
    bottom: -55px;
    font-family: var(--Manrope);
  }

  .vf_main_title {
    font-size: 35px;
  }

  .vf_takeways_padding_left {
    padding-left: 0px;
  }

  .vf_text_desc {
    font-size: 16px;
  }

  .gb_mv_mr,
  .vf_mv_mr {
    margin-top: 24px;
  }

  .vf_section_mt {
    margin-top: 80px;
  }

  .vf_left,
  .vf_right {
    width: 100%;
  }

  .vf_about_vf_container {
    flex-direction: column;
  }

  .vf_about_vf_container_takeways {
    flex-direction: column-reverse;
  }

  .add_responsive_mr {
    margin-top: 20px;
    display: block;
  }

  .vf_research_insight_container {
    padding-left: 0px;
    flex-direction: column;
    margin-top: 40px;
  }

  .vf_research_insight_paragraph {
    margin-top: 20px;
    font-size: 18px;
    width: 100%;
  }

  .vf_logo_image_container {
    justify-content: space-around;
  }

  .vf_user_flow_container {
    padding: 20px;
  }

  .vf_user_flow_image_container {
    margin-top: 20px;
  }

  .vf_takeWays_container {
    padding-bottom: 120px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .stepper_container_main {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 47% 47%;
  }

  .stepper_line_width {
    width: 337px;
  }

  .stepper_container_main :first-child {
    margin-top: 0;
  }

  .section5ContainerClass {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section5ContentContainerOne {
    display: grid;
    gap: 9%;
    grid-template-columns: 40% 50%;

    video {
      width: 100%;
      height: auto;
      padding-right: 90px;
    }
  }

  .section5TextContainer {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
  }

  .image_container {
    padding: 0 10px 0 20px;
  }

  .section5Text {
    font-size: 14px;
  }

  .section5ContentContainer {
    margin-top: 80px;
  }

  .gb_logo_image_container_bg {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .circle {
    width: 160px;
    height: 160px;
    font-size: 16px;
    right: 60px;
    bottom: -80px;
    font-family: var(--Manrope);
  }

  .vf_text_desc {
    font-size: 18px;
  }

  .vf_takeways_padding_left {
    padding-left: 40px;
  }

  .zto_goal_obj_desc {
    margin-top: 20px;
    font-size: 18px;
  }


  .zto_img_container_main {
    padding: 40px 20px;
  }

  .exp_container_main {
    margin-top: 20px;
  }

  .zto_img_container_data {
    width: 48%;
  }

  .exp_desc {
    margin-top: 24px;
    font-size: 18px;
  }

  .zto_adf_container {
    width: 100%;
    background: #F2F2F2;
    padding: 0;

    img {
      width: 100%;
    }
  }

  .exp_container {
    border-radius: 0px 0px 80px 80px;
  }

  .exp_container_padding1 {
    padding-bottom: 120px;
  }

  .blueDiv {
    height: auto;
    padding-bottom: 80px;
  }

  .blueDiv img {
    width: 100%;
    height: auto;
  }
}