.centerButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 5;
  opacity: 0;
}

.buttonsContainer {
  width: 999px;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 80px;
  border: 1px solid #8D838F;
  background: #FFF;
  padding: 0 12px;
}


.button {
  padding: 0 18px;
  height: 51px;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.selectedButton {
  background-color: #4A5BE1;
  color: white;
}

.circleButton {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  background-color: #4A5BE1;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.section5Image {
  object-fit: cover;
  flex: 1;
}

@media (min-width: 360px) and (max-width: 480px) {

  .buttonsContainer {
    width: 94%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 60px;
    padding: 0 8px;
  }

  .button {
    padding: 0 6px;
    height: 16px;
    flex-shrink: 0;
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 10px;
  }

  .centerButtons {
    margin-bottom: 16px;
  }

  .circleButton {
    width: 25px;
    height: 25px;

    img {
      width: 25px;
    }
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .buttonsContainer {
    width: 94%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 60px;
    padding: 0 8px;
  }

  .button {
    padding: 0 6px;
    height: 22px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 10px;
  }

  .centerButtons {
    margin-bottom: 16px;
  }

  .circleButton {
    width: 25px;
    height: 25px;

    img {
      width: 25px;
    }
  }
}