.header {
  width: 100%;
  height: 160px;
  padding: 56px 160px;
}

.nav_items {
  align-items: center;
  gap: 45px;
}

.header_font_style {
  font-family: var(--Helvetica_Neue);
  font-size: var(--font-20);
  font-weight: var(--font-weight-400);
  line-height: 23.86px;
}

.header_font_style_main {
  color: var(--color);
}

.header_font_style_white {
  color: #FFFFFF;
}

.hideHeader {
  opacity: 0;
}

.header_nav_container {
  height: 40px;
}

.dot_container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dot_container_opacity {
  height: 6px;
  width: 6px;
}

.dot_container_common {
  margin-top: 10px;
  border-radius: 50%;
  background: #FFFFFF;
}

.dot_container_common_white {
  background: #FFFFFF;
}

.dot_container_common_main {
  background: #1C1D20;
}

.dot_on_hover {
  height: 0px;
  width: 0px;
}

.mobile_menu_container {
  background: #1E1E1E;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
}

.mobile_menu_navigation {
  color: #FFFFFF;
  font-family: var(--Manrope);
  font-weight: 600;
  font-size: 16px;
  line-height: 27.84px;
  letter-spacing: 8%;
  width: calc(100% - 72px);
  height: 100%;
  border-bottom: 0.5px solid #8D8E8F
}

.mobile_menu_close {
  width: 56px;
  height: 56px;
  gap: 10px;
  padding: 16px;
  border-radius: 55px;
  background: #4A5BE1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile_menu_nav {
  margin-top: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}

.menu_close {
  width: 20px;
  height: 20px;
}

.container_side_name {
  margin-top: 40px;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: var(--color4);

  .name_item {
    margin-top: 32px;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: var(--Manrope);
    font-weight: 500;
    font-size: 24px;
    line-height: 41.76px;
    letter-spacing: 0.08px;
    text-transform: uppercase;
  }

  .mr_left {
    margin-left: 12px;
  }

  .active {
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color4);
  }

  .inactive {
    opacity: 0;
    width: 10px;
    height: 10px;
  }

  .name_item :first-child {
    margin-top: 0;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #FFFFFF;
  }

  .menuIcon {
    height: 8px;
    width: 30px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .header {
    padding: 64px 80px;
  }
}