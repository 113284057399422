.not_found_container {
  margin-top: 60px;
  width: 730px;
  font-family: var(--Manrope);
  font-size: 20px;
  font-weight: 500;
  line-height: 30.9px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 90px;
}

.notfound_container {
  height: 100%;
}

.notfound_title {
  color: var(--color);
  font-size: 65px;
  font-family: var(--Manrope);
  font-weight: 500;
  padding-left: 200px;
}

.not_found_back_to_home_border_color {
  border: 1px solid rgba(79, 70, 70, 0.24);
  margin: 0 90px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .not_found_container {
    width: 100%;
    font-size: 16px;
    padding: 0 20px;
  }

  .notfound_title {
    font-size: 30px;
    font-weight: 500;
    padding-left: 20px;
  }

  .not_found_back_to_home_border_color {
    width: 90%;
    margin: 0px;
    left: 20px;
  }
}