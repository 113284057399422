.homepage_my_work_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--color5);
}

.homepage_my_journey_container {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  height: 550px;
  transition: all 0.1s ease-in-out;
}


.homepage_card_container {
  width: 265px;
  height: 479px;
  padding: 40px 24px;
  gap: 22px;
  border-radius: 20px;
  border: 1px solid var(--color11);
  overflow: hidden;
}

.homepage_exe_logo_name {
  height: 114px;
}

.homepage_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homepage_exe_name {
  font-family: var(--Manrope);
  font-size: 28px;
  font-weight: 500;
  line-height: 45.08px;
  text-align: left;
  color: var(--color);
}

.homepage_exe_d_duration {
  height: 132px;
  width: 210px;
  margin-top: 28px;
}

.homepage_exe_designation {
  font-family: var(--Manrope);
  font-size: 24px;
  font-weight: 500;
  line-height: 38.64px;
  text-align: left;
  color: var(--color);
}

.homepage_exe_role {
  display: none;
  margin-top: 28px;
  font-family: var(--Inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: var(--color);
  text-align: left;
}

@media (min-width: 360px) and (max-width: 480px) {
  .homepage_my_journey_container {
    margin-top: 0;
    display: grid;
    gap: 40px;
    justify-content: space-around;
    height: max-content;
    grid-template-columns: 100%;

  }

  .homepage_card_container {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .homepage_my_journey_container {
    margin-top: 0;
    display: grid;
    gap: 40px;
    justify-content: space-around;
    height: max-content;
    grid-template-columns: 47% 47%;

  }

  .homepage_card_container {
    width: 100%
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .homepage_exe_name {
    font-size: 22px;
  }

  .homepage_exe_designation {
    font-size: 18px;
  }

  .homepage_card_container {
    width: 100%;
  }
}