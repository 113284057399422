.footer_container {
  background-color: var(--color);
  color: var(--color4);
  padding-top: 100px;
  font-family: var(--Manrope);
  padding-bottom: 40px;
}

.footer_logo {
  height: 74px;
}

.footer_work_together {
  font-size: 74px;
  font-weight: 400;
  line-height: 112px;
  letter-spacing: 0.065em;
  text-align: left;
}

.footer_work_together_container {
  margin-top: 50px;
}

.footer_contact_conatiner {
  margin-top: 60px;
  width: 550px;
  font-family: var(--Manrope);
  font-size: 20px;
  font-weight: 500;
  line-height: 30.9px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .footer_contact_info_btn_border {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
} */

.footer_contact_info_btn__email {
  height: 72px;
  width: 304px;
}

.footer_contact_info_btn_number {
  height: 72px;
  width: 224px;
}

.info_main_container {
  width: 100%;
  margin-top: 70px;
}

.footer_info_container {
  width: inherit;
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_padding {
  padding: 0 40px;
}

.footer_info_data {
  width: 341px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_info_name_copy {
  font-family: var(--Manrope);
  /* font-size: 18px;
  line-height: 27.81px;
  text-align: left; */
}

.footer_info_name_copy {
  font-weight: 400;
}

.footer_info_name_yr {
  font-weight: 500;
}

.footer_location {
  font-family: var(--Manrope);
  /* font-size: 18px;
  line-height: 27.81px;
  text-align: left; */
}

.footer_location_heart_wt {
  font-weight: 400;
}

.footer_location_color {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 154.5%;
  /* 27.81px */
}

.social_container_links {
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_social_media_container {
  width: 190px;
  font-family: var(--Manrope);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer_linkedIn {
  font-size: 18px;
  font-weight: 500;
  line-height: 27.81px;
  text-align: left;
}

.footer_info_version_title {
  color: rgba(255, 255, 255, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 154.5%;
  margin-bottom: 16px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .footer_container {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .footer_padding {
    padding: 0 20px;
  }

  .footer_work_together {
    font-family: var(--Manrope);
    font-weight: 500;
    font-size: 36px;
    line-height: 50.4px;
    letter-spacing: 0.65px;
  }

  .info_main_container {
    margin-top: 10px;
  }

  .footer_info_container {
    height: max-content;
    display: flex;
    align-items: unset;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
  }

  .footer_info_data {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: unset;
    justify-content: flex-start;
    flex-direction: column;
  }

  .footer_location {
    margin-top: 40px;
  }

  .footer_social_media_container {
    margin-top: 40px;
  }

}