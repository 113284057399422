.about_common_class {
  position: relative;
  color: #fff;
  border-radius: 50%;
  background-color: var(--color);
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-family: var(--Manrope);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.not_found_common_class {
  position: relative;
  color: var(--color);
  border-radius: 50%;
  background-color: var(--color);
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-family: var(--Manrope);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.get_in_touch_class {
  z-index: 1;
  overflow: hidden;
  position: relative;
  width: 185px;
  height: 185px;
  left: 70%;
  border-radius: 50%;
  background-color: var(--color13);
  font-family: var(--Manrope);
  font-size: 22px;
  font-weight: 400;
  line-height: 33.99px;
  color: var(--color4);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about_common_circle_class {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.view_mira_board_name_style {
  border-radius: 40px;
  background-color: inherit;
  cursor: pointer;
  font-size: 20px;
  color: var(--color);
  font-weight: 500;
}

.view_mira_board_name_style :hover {
  color: var(--color4);
  transition: color ease-in 0.3s;
}

.view_mira_board_border {
  border: 1px solid rgba(137, 137, 136, 0.24);
}

.about_me_button {
  font-size: 22px;
  font-weight: 500;
  line-height: 43.26px;
  width: 185px;
  height: 185px;
}

.about_me_button_circle {
  width: 185px;
  height: 185px;
}

.menu_button_circle {
  width: 100px;
  height: 100px;
}

.about_me_btn_name {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  z-index: 4;
}

/* side header css */

.menu_buttton_witout_effect {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background-color: var(--color);
  transition: height 0.5s ease-in, width 0.5s ease-in, border-radius 0.5s ease-in;

  svg {
    margin-top: 15px;
    height: 0px;
    width: 0px;
    transition: height 0.5s ease-in, width 0.5s ease-in;
  }
}

.menu_button_effect {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  transition: height 0.5s ease-in, width 0.5s ease-in, border-radius 0.5s ease-in;
  border: 1px solid rgba(255, 255, 255, 0.2);

  svg {
    height: 35px;
    width: 35px;
    transition: height 0.5s ease-in, width 0.5s ease-in;
  }
}

.svg_margin {
  svg {
    margin-top: 8px;
    transition: all 0.5s ease-in;
  }
}


.footer_contact_info_btn_border {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 40px;
  display: flex;
  background-color: inherit;
}

.not_found_contact_info_btn_border {
  border: 1px solid rgba(40, 36, 36, 0.4);
  border-radius: 40px;
  display: flex;
  background-color: inherit;
}

.foote_btn_font_style {
  font-size: 20px;
  font-weight: 500;
  line-height: 30.9px;
}

.not_found_btn_font_style {
  font-size: 20px;
  font-weight: 500;
  line-height: 30.9px;
}

.next_case_font_style {
  font-size: 18px;
  font-weight: 450;
  line-height: 30.9px;
}

.footer_btn_height_width_style {
  height: 72px;
  width: 304px;
}

.miraboard_height_width_style {
  height: 60px;
  width: 224px;
}


.viewbook_height_width_style {
  height: 71px;
  width: 175px;
}

.next_case_height_width_style {
  height: 60px;
  width: 160px;
}

.footer_contact_info_btn_number {
  height: 72px;
  width: 224px;
}

.footer_contact_info_btn_email_circle {
  position: absolute;
  top: 130%;
  left: 50%;
  background-color: var(--color13);
  /* border-radius: 50%; */
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.next_case_circle {
  position: absolute;
  top: 10%;
  left: 50%;
  height: 60px;
  width: 160px;
  background-color: var(--color13);
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.next_case_all_work_btn {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  height: 50px;
  width: 150px;
}

.next_case_all_work_btn_circle {
  height: 60px;
  width: 160px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .get_in_touch_class {
    width: 110px;
    height: 110px;
    font-size: 14px;
  }

  .not_found_btn_font_style {
    font-size: 14px;
    font-weight: 500;
    line-height: 30.9px;
  }

  .footer_btn_height_width_style {
    height: 50px;
    width: 58%;
  }

  .footer_contact_info_btn_number {
    height: 50px;
    width: 40%;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .get_in_touch_class {
    height: 165px;
    width: 165px;
    font-size: 20px;
  }
}