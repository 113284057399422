.loader_container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.loader {
  background: var(--color);
  width: 100%;
  height: 10%;
  bottom: 0;
  transform: scale(1.5);
  position: absolute;
  border-radius: 50%;
}

.loader_page_name {
  font-size: 35px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: space-around;
  color: var(--color4);
}


.container {
  background: var(--color);
  height: 100%;
  width: 100%;
  position: relative;
  font-family: var(--Manrope);
  color: var(--color4);
  filter: contrast(15);
}

@keyframes blink {

  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {

    opacity: 1;
  }

  50%,
  80% {

    opacity: 0;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .loader_page_name {
    font-size: 25px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 1;
    justify-content: space-around;
    color: var(--color4);
  }

}