.side_header_container {
  position: sticky;
  top: 45px;
  right: 45px;
  float: right;
  z-index: 5;
}

.side_header_tablet_container {
  position: sticky;
  top: 45px;
  right: 45px;
  float: right;
  z-index: 5;
}

.modal_container {
  float: right;
  height: 100%;
  width: 560px;
}

.modal_contaier_data {
  margin-top: 120px;
  padding: 0 64px 64px 64px;
  font-family: var(--Manrope);
  color: var(--color4);

  .side_bar_border {
    margin-top: 27px;
    width: 251px;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1px;
  }

  .conatiner_navigation {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 600;
    line-height: 27.84px;
    letter-spacing: 0.08em;
    text-align: left;
  }

  .container_side_name {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .name_item {
      font-size: 56px;
      font-weight: 500;
      line-height: 69.6px;
      letter-spacing: normal;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .mr_left {
      margin-left: 12px;
    }

    .active {
      opacity: 1;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--color4);
    }

    .inactive {
      opacity: 0;
      width: 10px;
      height: 10px;
    }

    .name_item :first-child {
      margin-top: 0;
    }
  }
}

.margin_top {
  margin-top: 30px;
}