.tool_title {
  font-family: var(--Manrope);
  font-size: 14px;
  font-weight: 600;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--color5);
}

.tool_container {
  display: flex;
  align-items: center;
}

.tool_logo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 87px;
  height: 85px;
  border-radius: 1px;
  background: var(--color17);
}

.tool_logo_size {
  height: 40px;
}

.tool_desc_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--Inter);
  font-size: 20px;
  line-height: 34.8px;
  margin-left: 16px;
  text-align: left;
}

.tool_name {
  color: var(--color);
  font-weight: 500;
}

.tool_type {
  font-weight: 400;
  color: var(--color18);
}


.tool_container_all {
  margin-top: 56px;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  column-gap: 80px;
  row-gap: 80px;
}

@media (min-width: 360px) and (max-width: 835px) {
  .tool_container_all {
    margin-top: 40px;
    column-gap: 40px;
    row-gap: 40px;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .tool_container_all {
    grid-template-columns: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .tool_container_all {
    grid-template-columns: 47% 47%;
  }
}