.about_container {
  font-family: var(--Manrope);
}

.about_info {
  width: 582px;
  height: 194px;
  position: relative;
  margin-top: 162px;
  left: 150px;
  padding: 10px;
  font-size: 64px;
  font-weight: 500;
  line-height: 87.42px;
  text-align: left;
  color: var(--color14);
}


.about_me_container {
  width: 100%;
  /* height: 840px; */
  /* top: 867px; */
  display: flex;
  margin-top: 140px;
}

.about_me_container_left {
  width: 58px;
  margin-top: 12px;
}

.about_me_container_right {
  /* width: 1205px;
  height: 752px; */
  display: flex;
  justify-content: space-between;
}

.about_explore {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--color16);
}

.right {
  width: 49%;
  /* height: 840px; */
  background: #F7F7F7;
  padding: 40px 50px 40px 20px;
}

.right_about_me {
  /* padding-left: 46px; */
  /* width: 362px; */
  /* height: 280px; */
  font-family: var(--Manrope);
  text-align: left;
}

.itemText {
  margin-top: 32px;
  font-weight: 600;
  font-size: 14px;
  color: #8D8E8F;
  text-transform: uppercase;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
}

.itemText2 {
  font-family: var(--Manrope);
  color: #8D838F;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.63px;
  letter-spacing: 0.02em;
}

.about_me_title {
  color: var(--color1);
  font-family: var(--Manrope);
  font-size: 28px;
  font-weight: 500;
  line-height: 43.26px;
  letter-spacing: 0.02em;
  text-align: left;
}

.about_me_desc {
  margin-top: 12px;
  font-family: var(--Manrope);
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #6A6969;
}

.left {
  width: 49%;
  height: 100%;
  border-radius: 4px;
  /* background: var(--color15); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.left_about_me {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 350px;
  /* width: 312px; */

  img {
    height: 350px;
    width: 300px;
    border-radius: 12px;
  }
}

.about_my_journey_container {
  margin-top: 160px;
}

.about_tools_container {
  margin-top: 160px;
}

.area_of_interest {
  margin-top: 160px;
  font-family: var(--Manrope);
}

.area_left {
  width: 436.2px;

  .area_left_div1 {
    width: 100%;

    img {
      height: 542px;
      width: inherit;
    }
  }

  .area_left_div2 {
    width: 100%;

    img {
      height: 496px;
      width: inherit;
    }
  }
}

.area_middle {
  width: 453.39px;
  margin-left: 12px;

  .area_left_div1 {
    width: 100%;

    img {
      height: 367px;
      width: inherit;
    }
  }

  .area_left_div2 {
    margin-top: 12px;
    width: 100%;

    img {
      height: 582px;
      width: inherit;
    }
  }
}

.area_right {
  width: 373.88px;
  margin-left: 12px;
  margin-top: 80px;

  .area_left_div1 {
    width: 100%;

    img {
      height: 367px;
      width: inherit;
    }
  }

  .area_left_div2 {
    margin-top: 12px;
    width: 100%;

    img {
      height: 582px;
      width: inherit;
    }
  }
}


.area_lebel {
  display: block;
  margin: 12px 0;
  font-family: var(--Inter);
  font-size: 28px;
  font-weight: 450;
  line-height: 33.89px;
  text-align: left;
  color: var(--color);
}

.area_of_interest_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24.36px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--color5);
}

.area_of_interest_container {
  margin-top: 56px;
  display: flex;
}

.about_project_mind_container_parent {
  background-color: var(--color);
}

.about_project_mind_container {
  background-color: var(--color4);
  font-family: var(--Manrope);
  padding-bottom: 160px;
  border-radius: 0 0 100px 100px;
  margin-top: 200px;

  .about_project_title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34.8px;
    text-align: left;
  }

  .project_title_underline {
    margin-top: 16px;
    width: 238px;
    border: 1px solid var(--color19);
  }

  .project_desc {
    margin-top: 70px;
    width: 715px;
    height: 210px;
    font-size: 20px;
    line-height: 34.8px;
    color: var(--color);

    .project_desc_span1 {
      font-weight: 500;
      text-align: left;
    }
  }
}

@keyframes animatedDot1 {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
}

.animate_dot1 {
  opacity: 0;
  animation: animatedDot1 2s linear infinite;
}

@keyframes animatedDot2 {
  0% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
}

.animate_dot2 {
  opacity: 0;
  animation: animatedDot2 2s linear infinite;
}



@keyframes animatedDot3 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
}

.animate_dot3 {
  opacity: 0;
  animation: animatedDot3 2s linear infinite;
}

.border_download_resume {
  height: 1px;
  background: #1818184D;
  margin-left: 170px;
}

.nameClassName {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 60%;
  z-index: 4;
}

.linkClassName {
  z-index: 1;
  overflow: hidden;
  position: relative;
  width: 185px;
  height: 185px;
  left: 82%;
  border-radius: 50%;
  background-color: var(--color13);
  font-family: var(--Manrope);
  font-size: 22px;
  font-weight: 400;
  line-height: 33.99px;
  color: var(--color4);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.help_conatiner {
  padding-top: 160px;
  font-family: var(--Manrope);

  h1 {
    font-size: 64px;
    font-weight: 500;
    line-height: 87.42px;
    text-align: left;
    color: var(--color);
  }

  .help_section_container {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    align-items: center;

    .help_section_title {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.12px;
      text-align: left;
      color: var(--color5);
      width: 391.52px;
      padding-bottom: 4px;
      border-bottom: 1px solid #00000033;
    }

    .help_section_type {
      margin-top: 40px;
      font-size: 28px;
      font-weight: 500;
      line-height: 43.26px;
      text-align: left;
      color: var(--color);
    }

    .help_section_category {
      font-size: 20px;
      font-weight: 500;
      line-height: 30.9px;
      text-align: left;
      color: #00000080;

      p:first-child {
        margin-top: 30px;
      }

      p {
        margin-top: 12px;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .about_info {
    width: 89%;
    height: 100px;
    margin-top: 80px;
    left: 20px;
    padding: 10px;
    font-weight: 500;
    font-family: var(--Manrope);
    font-size: 32px;
    line-height: 43.71px;
  }

  .border_download_resume {
    height: 0px;
    margin-left: 0px;
  }

  .linkClassName {
    width: 110px;
    height: 110px;
    left: 60%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21.63px;
  }

  .about_me_container {
    width: 100%;
    display: flex;
    margin-top: -55px;
  }

  .about_me_container_right {
    display: grid;
    justify-content: unset;
    grid-template-columns: 100%;
  }

  .right {
    width: 100%;
    padding: 20px;
  }

  .left {
    margin-top: 80px;
    width: 100%;
    height: max-content;
    border-radius: 4px;
    display: grid;
    flex-direction: unset;
    align-items: center;
    grid-template-columns: 100%;
    gap: 12px;
  }

  .left_about_me {
    display: grid;
    grid-template-columns: 100%;
    align-items: unset;
    justify-content: unset;
    height: max-content;

    img {
      height: 350px;
      width: 100%;
      border-radius: 12px;
    }
  }

  .help_conatiner {
    padding-top: 80px;
    font-family: var(--Manrope);

    h1 {
      font-size: 64px;
      font-weight: 500;
      line-height: 87.42px;
      text-align: left;
      color: var(--color);
    }

    .help_section_container {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 100%;
      gap: 40px;
      align-items: center;

      .help_section_title {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.12px;
        text-align: left;
        color: var(--color5);
        width: 100%;
        padding-bottom: 4px;
        border-bottom: 1px solid #00000033;
      }

      .help_section_type {
        margin-top: 20px;
        font-size: 28px;
        font-weight: 500;
        line-height: 43.26px;
        text-align: left;
        color: var(--color);
      }

      .help_section_category {
        font-size: 20px;
        font-weight: 500;
        line-height: 30.9px;
        text-align: left;
        color: #00000080;

        p:first-child {
          margin-top: 30px;
        }

        p {
          margin-top: 12px;
        }
      }
    }
  }


  .about_project_mind_container {
    padding-bottom: 100px;
    border-radius: 0 0 40px 40px;
    margin-top: 80px;

    .about_project_title {
      font-size: 20px;
      font-weight: 500;
      line-height: 34.8px;
      text-align: left;
    }

    .project_title_underline {
      margin-top: 16px;
      width: 238px;
      border: 1px solid var(--color19);
    }

    .project_desc {
      margin-top: 70px;
      width: 715px;
      height: 210px;
      font-size: 20px;
      line-height: 34.8px;
      color: var(--color);

      .project_desc_span1 {
        font-weight: 500;
        text-align: left;
      }
    }
  }

  .about_me_desc {
    font-size: 14px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .about_info {
    width: 562px;
    margin-top: 130px;
    left: 50px;
    padding: 10px;
  }

  .border_download_resume {
    margin-left: 40px;
    width: 70%;
  }

  .linkClassName {
    width: 160px;
    height: 160px;
    left: 60%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21.63px;
  }

  .left {
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }

  .left_about_me {
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 20px;

    iframe {
      margin-left: 0 !important;
      height: 160px !important;
    }

    img {
      height: 170px;
      width: 100%;
      border-radius: 12px;
    }
  }

  .help_conatiner {
    padding-top: 120px;
    font-family: var(--Manrope);

    .help_section_container {
      gap: 40px;
      grid-template-columns: repeat(2, 47%);

      .help_section_title {
        width: 337px;
      }
    }
  }

  .about_project_mind_container {
    padding-bottom: 120px;
    border-radius: 0 0 40px 40px;
    margin-top: 80px;
  }
}