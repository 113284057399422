.gridViewContainer {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding-left: 160px;
  padding-right: 160px;
  padding-bottom: 102px;
  border-radius: 0px 0px 100px 100px;
  background-color: #ffffff;
}

.work_container_bg {
  background: var(--color);
}

.gridViewContainer:nth-child(even) {
  padding-right: 0px;
}

.title {
  color: #1C1D20;
  font-family: var(--Manrope);
  font-size: 64px;
  font-weight: 500;
  margin-left: 170px;
  margin-top: 140px;
  width: 562px;
}

.gridViewItemOdd {
  width: 100%;
  z-index: 1;
  /* overflow: hidden; */
  padding-top: 120px;
  display: flex;
  justify-content: flex-end;
}

.gridViewItemEven {
  width: 536px;
  z-index: 1;
  /* overflow: hidden; */
  padding-top: 120px;
}

.gridViewImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 61px;
}

.gridViewImage {
  width: 413px;
  height: 275px;
  object-fit: contain;
}

.gridViewTitle {
  margin-top: 60px;
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;

}

.gridViewSeparator {
  margin: 20px 0px 16px 0px;
  border: 1px solid rgba(30, 30, 30, 0.20);
}

.gridViewDescription {
  color: #1E1E1E;
  font-family: var(--Manrope);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-transform: uppercase;
}

.circleButton {
  font-size: var(--Manrope);
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color13);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color4);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.circle_view {
  transform: scale(0.1);
}

@media (min-width: 360px) and (max-width: 480px) {
  .title {
    font-weight: 500;
    margin-left: 20px;
    margin-top: 80px;
    width: max-content;
    font-size: 32px;
    line-height: 43.71px;
  }

  .gridViewContainer {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 0px 0px 40px 40px;
    padding-bottom: 60px;
  }

  .gridViewItemEven,
  .gridViewItemOdd {
    width: 100%;
    z-index: 1;
    /* overflow: hidden; */
    padding-top: 40px;
  }

  .gridViewImage {
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }

  .gridViewTitle {
    margin-top: 20px;
    font-size: 20px;
  }

  .gridViewDescription {
    font-size: 14px;
  }

  .gridViewSeparator {
    margin: 10px 0px 10px 0px;
  }

  .gridViewImageContainer {
    padding: 20px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .gridViewContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
    border-radius: 0px 0px 80px 80px;
    flex-direction: column;
  }

  .gridViewItemOdd {
    justify-content: space-around;
  }
}