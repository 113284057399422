.topContainer {
    margin-top: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .calorie_wise_container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.calorie_wise_product {
    color: var(--color);
    font-family: var(--Manrope);
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    height: 38px;
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid var(--color15);
}


.title {
    font-size: 64px;
    font-weight: 500;
    color: #1C1D20;
    font-family: var(--Manrope);
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 100px;
    padding-left: 160px;
    padding-right: 120px;
}

.item {
    width: 351px;
}

.tag {
    padding: 8px 16px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #D9D9D9;
    color: #1E1E1E;
    margin-right: 16px;
}

.tag2 {
    padding: 8px 16px;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #D9D9D9;
    color: #1E1E1E;
    margin-right: 16px;
    margin-top: 18px;
}

.tag:last-child {
    margin-right: 0;
}


.itemText {
    font-weight: 600;
    font-size: 14px;
    color: #8D8E8F;
    text-transform: uppercase;
    font-family: var(--Manrope);
    line-height: 24.36px;
    letter-spacing: 0.08em;
    text-align: left;
}

.line {
    width: 351px;
    height: 1px;
    background-color: #1818184D;
    margin: 0 auto;
    margin-top: 21px;
    margin-bottom: 40px;
}

.paragraph {
    width: 351px;
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 500;
    font-family: var(--Inter);
    line-height: 22.4px;
    text-align: left;
}


.circle {
    width: 200px;
    height: 200px;
    background-color: #4A5BE1;
    border-radius: 50%;
    display: flex;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    position: absolute;
    z-index: 1;
    right: 80px;
    bottom: -100px;
    font-family: var(--Manrope);
}

.main {
    position: relative;
    /* padding-top: 220px; */
}


.blueDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #E6EDFB;
    padding-bottom: 80px;
}

.imageContainer {
    height: 640px;
    background: #FBF5F1;
}

.imageContainer img {
    height: 100%;
    object-fit: contain;
}

.thought {
    width: 100%;
    height: auto;
}

.section3LeftContainer {
    width: 50%;
}

.section3GoalText {
    font-weight: 600;
    font-size: 14px;
    color: #8D8E8F;
    text-transform: uppercase;
    font-family: var(--Manrope);
}

.section3GoalDescription {
    font-size: 24px;
    color: #1E1E1E;
    font-weight: 500;
    margin-top: 24px;
    width: 90%;
    font-family: var(--Manrope);
    font-family: Manrope;
    line-height: 33.6px;
    text-align: left;
}

.section3RightContainer {
    width: 50%;
    /* padding-left: 50px; */
}

/* .section3ToolsText {
    font-weight: 500;
    font-size: 14px;
    color: #8D8E8F;
    text-transform: uppercase;
} */

.section3ToolsContainer {
    display: flex;
    margin-top: 6px;
    flex-wrap: wrap;
}

.section3Container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 120px;
}

.section3ProblemStatementContainer {
    width: 40%;
}

/* .section3ProblemStatementTitle {
    font-weight: 600;
    font-size: 14px;
    color: #8D8E8F;
    text-transform: uppercase;
    font-family: var(--Manrope);
} */

.section3ProblemStatementContent {
    margin-top: 20px;
}

.section_circle {
    margin: 0 10px 0 0;
    font-size: 18px;
    margin-top: -3px;
}

.section3ProblemPoint {
    display: flex;
    font-weight: 400;
    margin-top: 16px;
    font-size: 18px;
}

.section3ProblemPointBold {
    /* font-size: 18px;
    color: var(--color2); */
    font-weight: 500;
    margin-top: 16px;
    /* font-family: var(--Inter); */
}

.section4Text {
    font-size: 24px;
    color: var(--color2);
    font-weight: 400;
    margin-top: 16px;
    text-decoration: underline;
    text-underline-position: under;
    font-family: var(--Inter);
}

.section3ChallengeContainer {
    width: 50%;
}

.section3ProblemStatementContent {
    margin-top: 6px;
    flex-wrap: wrap;
    font-size: 18px;
    color: var(--color2);
    font-family: var(--Inter);
    font-family: Inter;
    line-height: 25.2px;
    text-align: left;
}

.section3ChallengeContent {
    display: flex;
    margin-top: 6px;
    flex-wrap: wrap;
    font-size: 18px;
    color: var(--color2);
    font-family: var(--Inter);
    font-family: Inter;
    line-height: 25.2px;
    text-align: left;
}

.secondry_research_section {
    margin-top: 120px;
}

.secondry_research_section_padding {
    padding-top: 120px !important;
    padding-bottom: 80px !important;
}

.bg_secod_search {
    background: var(--color20);
    display: flex;
    padding: 80px 0 0 52px;
}

.section3ChallengeText {
    font-size: 24px;
    color: #1E1E1E;
    font-weight: 500;
    margin-top: 20px;
    font-family: var(--Manrope);
    font-family: Manrope;
    line-height: 38.4px;
    text-align: left;
}

.section3ChallengeTextBold {
    font-size: 24px;
    color: #1E1E1E;
    font-weight: 600;
    margin-top: 20px;
    font-family: var(--Manrope);
}

.containerSection3 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 120px;
}

.book {
    height: 600px;
    width: 400px;
}

.section4Container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.section4Content {
    margin-top: 20px;
}

/* .section4TitleText {
    font-size: 12px;
    color: #8D8E8F;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;

    font-family: var(--Manrope);
} */

.section4InnerText {
    font-size: 24px;
    color: var(--color2);
    font-weight: 400;
    margin-top: 16px;
    font-family: var(--Inter);
}

.section4InnerContent {
    margin-top: 30px;
}

.group {
    width: 100%;
    height: auto;
}

.section4RightInnerContainer {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.thoughtsContainer {
    margin-top: 120px;
    margin-bottom: 80px;
}

.thoughtsParagraphContainer {
    margin-top: 60px;
    width: 1000px;
}

.thoughtsParagraph,
.thoughtsParagrap2 {
    font-size: 20px;
    color: var(--color2);
    font-weight: 400;
    font-family: var(--Inter);
    line-height: 28px;
    text-align: left;
}

.thoughtsParagraphBold {
    font-size: 20px;
    color: var(--color2);
    font-weight: 500;
    font-family: var(--Inter);
}

.researchParagraphContainer {
    margin-top: 120px;
    width: 844px;
}

.researchParagraph {
    margin-top: 27px;
}

.insightParagraph {
    font-size: 20px;
    color: var(--color2);
    font-weight: 400;
    font-family: var(--Helvetica_Neue);
}

.insightParagraphBold {
    font-size: 20px;
    color: var(--color2);
    font-weight: 500;
    font-family: var(--Helvetica_Neue);
}


.whatItContainer {
    margin-top: 64px;
}

.whatIt2Container {
    margin-top: 64px;
    margin-right: 40px;
}

.whatIt3Container {
    margin-top: 72px;
}

.insight3Container1 {
    background: var(--color4);
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.insight3Container {
    margin-top: 120px;
    padding-bottom: 200px;
}

.insight3Main {
    padding-bottom: 160px;
    background-color: var(--color);
}

.insight3ImageContainer {
    margin-top: 64px;
}

.researchinsightContainerWrapper {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-top: 60px;
}

.researchinsightContainer {
    padding-left: 48px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    align-items: center;
}

.researchinsightImageContainer {
    display: flex;
    flex-direction: column;
}

.researchinsightSmallImage {
    height: 128px;
    width: 128px;
}

.researchinsightSmallText {
    font-size: 24px;
    font-weight: 400;
    margin-top: 12.98px;
    font-style: italic;
    color: #000000;
    font-family: var(--Helvetica_Neue);
}

.researchInsightParagraphContainer {
    display: flex;
    justify-content: flex-end;
}

.researchinsightParagraph {
    font-style: italic;
    font-size: 24px;
    font-weight: 400;
    color: #1E1E1E;
    width: 90%;
    font-family: var(--Helvetica_Neue);
}

@media (min-width: 360px) and (max-width: 480px) {

    .blueDiv {
        width: 100%;
        height: 356px;
        padding: 0 20px;
        padding: 80px 0 120px 0;
    }

    .imageContainer {
        height: 100%;
        width: 80%;
    }

    .imageContainer img {
        height: auto;
        width: 100%;
        object-fit: contain;
    }

    .main {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .section3Container {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 80px;
    }

    .circle {
        width: 110px;
        height: 110px;
        font-weight: 600;
        font-size: 14px;
        z-index: 1;
        right: 20px;
        bottom: -56px;
    }

    .title {
        font-size: 32px;
        line-height: 43.71px;
    }

    .topContainer {
        margin: 60px 0px 0 0px;
        padding: 0 20px;
    }

    .container {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
        padding: 0 20px;

    }

    .containerSection3 {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 80px;
    }

    .section3RightContainer {
        padding-left: 0px;
        margin-top: 40px;
        width: 100%;
    }

    .section3ProblemStatementContainer,
    .section3ProblemStatementContent,
    .section3LeftContainer,
    .section3ChallengeContainer,
    .section3GoalDescription,
    .item,
    .line,
    .paragraph,
    .section4Container,
    .section4RightInnerContainer,
    .thoughtsParagraphContainer,
    .researchinsightParagraph {
        width: 100%;
    }

    .section3ProblemPoint {
        font-size: 16px;
    }

    .line {
        margin-top: 20px;
        margin-bottom: 28px;
    }

    .paragraph {
        font-size: 18px;
        line-height: 25.2px;
        width: 100%;
    }

    .whatIt2Container {
        margin-top: 56px;
        margin-right: 0px;
    }

    .item {
        .itemText {
            margin-top: 40px;
        }
    }

    .bg_secod_search {
        display: grid;
        grid-template-columns: 100%;
        padding: 40px 20px 0 20px;
    }

    .book {
        height: 450px;
        width: 100%;
    }

    .secondry_research_section_padding {
        padding-top: 80px !important;
    }

    .section4InnerText {
        font-size: 16px;
        line-height: 32px;
    }

    .section4Text {
        font-size: 16px;
        line-height: 32px;
    }

    .section3ChallengeTextBold {
        font-size: 18px;
    }

    .section3ChallengeContainer,
    .section4RightInnerContainer,
    .researchinsightParagraph {
        margin-top: 40px;
    }

    .researchinsightParagraph {
        font-family: var(--Inter);
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        line-height: 22.4px;
    }

    .thoughtsParagraph,
    .thoughtsParagraphBold {
        font-size: 14px;
        line-height: 19.6px;
    }

    .thoughtsParagrap2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.4px;
    }

    .insightParagraph {
        font-family: var(--Inter);
        font-size: 16px;
        line-height: 22.4px;
    }

    .researchParagraphContainer {
        margin-top: 80px;
        width: 100%;
    }

    .researchinsightContainer {
        padding-left: 0px;
        display: grid;
        grid-template-columns: 100%;
    }

    .researchinsightImageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .researchinsightSmallText {
        font-size: 20px;
        line-height: 28px;
    }

    .insight3Container1 {
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
    }

    .insight3Container {
        margin-top: 80px;
        padding-bottom: 80px;
    }

    .insight3Main {
        padding-bottom: 80px;
    }

    .thoughtsContainer {
        margin-top: 80px;
        margin-bottom: 20px;
    }

    .section3ChallengeText {
        font-size: 18px;
        line-height: 25.2px;
    }

    .section4Container {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 80px;
        padding-bottom: 0px;
    }

    .secondry_research_section {
        margin-top: 80px;
    }

    .section3GoalDescription {
        font-size: 18px;
        line-height: 25.2px;
    }

}

@media (min-width: 481px) and (max-width: 1024px) {
    .blueDiv {
        width: 100%;
        height: auto;
        padding: 0 20px;
        padding: 16px 0 110px 0;
    }

    .imageContainer {
        height: 100%;
        width: 80%;
        background: inherit;
    }

    .imageContainer img {
        height: auto;
        width: 100%;
        object-fit: contain;
    }

    .main {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .section3Container {
        display: grid;
        grid-template-columns: 47% 47%;
        margin-top: 80px;
    }

    .circle {
        width: 160px;
        height: 160px;
        font-weight: 600;
        font-size: 16px;
        z-index: 1;
        right: 80px;
        bottom: -75px;
    }

    .title {
        font-size: 32px;
        line-height: 43.71px;
    }

    .topContainer {
        margin: 60px 0px 0 0px;
        padding: 0 20px;
    }

    .container {
        display: grid;
        grid-template-columns: 47% 47%;
        margin-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
        padding: 0 20px;

    }

    .containerSection3 {
        display: grid;
        grid-template-columns: 47% 47%;
        margin-top: 80px;
    }

    .section3RightContainer {
        padding-left: 0px;
        margin-top: 40px;
        width: 100%;
    }

    .section3ProblemStatementContainer,
    .section3ProblemStatementContent,
    .section3LeftContainer,
    .section3ChallengeContainer,
    .section3GoalDescription,
    .item,
    .line,
    .paragraph,
    .section4Container,
    .section4RightInnerContainer,
    .thoughtsParagraphContainer,
    .researchinsightParagraph {
        width: 100%;
    }

    .section3ProblemPoint {
        font-size: 16px;
    }

    .line {
        margin-top: 20px;
        margin-bottom: 28px;
    }

    .paragraph {
        font-size: 18px;
        line-height: 25.2px;
        width: 100%;
    }

    .whatIt2Container {
        margin-top: 56px;
        margin-right: 0px;
    }

    .item {
        .itemText {
            margin-top: 40px;
        }
    }

    .bg_secod_search {
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
        padding: 40px 20px 0 20px;
    }

    .book {
        height: 450px;
        width: 100%;
    }

    .secondry_research_section_padding {
        padding-top: 80px !important;
    }

    .section4InnerText {
        font-size: 16px;
        line-height: 32px;
    }

    .section4Text {
        font-size: 16px;
        line-height: 32px;
    }

    .section3ChallengeTextBold {
        font-size: 18px;
    }

    .section3ChallengeContainer {
        margin-top: 0px;
    }

    .section4RightInnerContainer,
    .researchinsightParagraph {
        margin-top: 40px;
    }

    .researchinsightParagraph {
        font-family: var(--Inter);
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        line-height: 22.4px;
    }

    .thoughtsParagraph,
    .thoughtsParagraphBold {
        font-size: 14px;
        line-height: 19.6px;
    }

    .thoughtsParagrap2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.4px;
    }

    .insightParagraph {
        font-family: var(--Inter);
        font-size: 16px;
        line-height: 22.4px;
    }

    .researchParagraphContainer {
        margin-top: 80px;
        width: 60%;
    }

    .researchinsightContainer {
        padding-left: 0px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 22% 70%;
    }

    .researchinsightImageContainer {
        display: flex;
        flex-direction: column;
    }

    .researchinsightSmallText {
        font-size: 20px;
        line-height: 28px;
    }

    .insight3Container1 {
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
    }

    .insight3Container {
        margin-top: 80px;
        padding-bottom: 80px;
    }

    .insight3Main {
        padding-bottom: 80px;
    }

    .thoughtsContainer {
        margin-top: 80px;
        margin-bottom: 20px;
    }

    .section3ChallengeText {
        font-size: 18px;
        line-height: 25.2px;
    }

    .section4Container {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 0px;
        padding-bottom: 0px;
    }

    .secondry_research_section {
        margin-top: 80px;
    }

    .section3GoalDescription {
        font-size: 18px;
        line-height: 25.2px;
    }

}